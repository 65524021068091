import React from "react";
import Ecommerce from "../Katchup/components/ECommerce/Ecommerce";
import News from "../Katchup/components/News/News";
import Diary from "../Katchup/components/Diary/Diary";
import Widgets from "../Katchup/components/KWidgets/Widgets";
function Kmail() {
  return (
    <div>
      <>
        <div className="col-md-12 d-flex align-items-center background_color p-2 overall-height">
          <div className="col-md-4">
            {/* <Recent />
        <SearchBar />
        <Date /> */}
            {/* <Ecommerce /> */}
          </div>
          <div className="col-md-4 px-2 height-scroll">
            <div>
              <News />
            </div>
            <div className="mt-3">
              <Diary />
            </div>
            <div>
              <Widgets />
            </div>
          </div>
          <div className="col-md-4">
            <Ecommerce />
          </div>
        </div>
      </>
    </div>
  );
}

export default Kmail;
