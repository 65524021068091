import React from "react";
import "./Widgets.css";

const Widgets = () => {
  const widgets = [
    {
      icon: "icon-KP_06-KDirectory",
      iconName: "KDirectory",
    },
    {
      icon: "icon-KP_88-Bus",
      iconName: "KBooking",
    },
    {
      icon: "icon-KP_12-KWallet",
      iconName: "KPay",
    },
    {
      icon: "icon-KP_211---Poster",
      iconName: "Poster",
    },
  ];

  return (
    <div className="w-100 Dairy-Container p-3">
      <div className="d-flex align-items-center gap-4 pb-3">
        <span className="ecomm_font">Widgets</span>
        <span className="icon-KP_250 cursor-pointer font-size"></span>
        <span className="icon-KP_236_Edit cursor-pointer font-size"></span>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        {widgets.map((x) => (
          <div className="d-flex flex-column gap-2 align-items-center meesho_background p-3 cursor-pointer">
            <span className={`${x.icon} Widgets-Icon`}></span>
            <span className="Widgets-Menu">{x.iconName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Widgets;
