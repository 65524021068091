import { EndPointURL } from "./ServiceURL";

export async function GetAllCountry(body) {
  try {
    const response = await fetch(`${EndPointURL}/common/countries`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
      }
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 403) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function GetAllLanguageByCountryId(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/languages`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }


  export async function GetAllDomainByCountryId(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/domain`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function CheckIsMobileNoExist(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/mobileNoExist`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function SendOTP(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/sendOTP`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function ValidateOTP(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/validateOTP`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }



  export async function postalPinCode(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/postalPinCode`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function CheckkpostIdExist(body) {
    try {
      const response = await fetch(`${EndPointURL}/signupLogin/kpostIdExist/`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function GetKpostIdSuggestion(body) {
    try {
      const response = await fetch(`${EndPointURL}/signupLogin/kpostIDsuggestionList`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function UserSignup(body) {
    try {
      const response = await fetch(`${EndPointURL}/signupLogin/signup`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }
  