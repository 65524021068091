import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Carousel/Carousel.css";

const Carousel = ({ Carousel }) => {
  const Slide = ({ x }) => {
    return (
      <div className="Carousel d-flex flex-column gap-3">
        <div>
          <img src={x.Img} alt="" className="Image-Size" />
        </div>

        <div className="d-flex align-items-center">
          <span className={`${x.class} Icon-size`}>
            {/* <img src={x.TitleIcon} alt="" width="65%" /> */}
          </span>
          <div className="Title-Font ml-2">{x.Title}</div>
        </div>

        <div className={`Text_Font Carousel-Font two-line-ellipsis`}>
          {x.Text}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Slider
        dots
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={4000}
        rtl={false}
        fade={true}
      >
        {Carousel.map((x, i) => (
          <Slide x={x} key={i} />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
