export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const CONTACTS = 'CONTACTS';

export const loginSuccess = (user, accessT, refreshT) => ({
  
  type: LOGIN_SUCCESS,
  payload: user,
  loginSuccess: true,
  accessToken: accessT,
  refreshToken: refreshT
});

export const logout = () => ({
  type: LOGOUT,
  loginSuccess: false
});

export const Katchupcontact = (contacts, unknownContacts, group, unknownGroup) => ({
  type: CONTACTS,
  myContacts: contacts,
  myUnknownContacts: unknownContacts,
  myGroup: group,
  myUnknownGroup: unknownGroup
});