import React, { useState } from "react";
import KpostLogo from "../Assets/headerImg.png";
// import Fade from "react-reveal/Fade";
import { Outlet, useNavigate } from "react-router-dom";

const Header = () => {
  const [hamburgerMenu, setHamburgerMenu] = useState(true);
  const [navWidth, setNavWidth] = useState(3.5);
  const [backColor, setBackColor] = useState("icon-KP_01-Home");

  const toggleNav = () => {
    setNavWidth((prevNavWidth) => (prevNavWidth === 3.5 ? 10 : 3.5));
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-3 py-1">
        <div className="d-flex gap-4 align-items-center">
          <div
            onClick={() => {
              setHamburgerMenu(!hamburgerMenu);
              toggleNav();
            }}
            className="icon-KP_19-Menu cursor-pointer header_icon"
          ></div>
          <div>
            <img
              src={KpostLogo}
              alt="Kpost Logo"
              width={100}
              className="header_image"
            />
          </div>
          {/* <div className="header_Title_Font">KPost</div> */}
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="photo_Border">
            <img
              src={
                "https://images.unsplash.com/photo-1511367461989-f85a21fda167?auto=format&fit=crop&q=80&w=1631&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              }
              alt="profilephoto"
              className="photo_style"
            />
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="header_font">Ramalinga Raju</div>
            <div className="icon-KP_22-Down-Arrow-22 header_icon_down"></div>
          </div>
        </div>
      </div>

      {/* <div className="d-flex flex-column "> */}
      <div>
        <div className="menu position-absolute">
          {hamburgerMenu ? (
            <div className="d-flex flex-column gap-4">
              <div
                onClick={() => {
                  navigate("/home");
                  // setBackColor("icon-KP_01-Home");
                }}
                className="d-flex gap-3 icon-KP_01-Home cursor-pointer menu_icon_font"
                // className={`"d-flex gap-3 icon-KP_01-Home cursor-pointer" ${
                //   backColor === "icon-KP_01-Home"
                //     ? "menu_icon_font_back"
                //     : "menu_icon_font"
                // }`}
              ></div>
              <div
                onClick={() => {
                  navigate("/writemail");
                  // setBackColor("icon-KP_02-Write-Letter");
                }}
                className="d-flex gap-3 icon-KP_02-Write-Letter cursor-pointer menu_icon_font"

                // className={`"d-flex gap-3 icon-KP_02-Write-Letter cursor-pointer" ${
                //   backColor === "icon-KP_02-Write-Letter"
                //     ? "menu_icon_font_back"
                //     : "menu_icon_font"
                // }`}
              ></div>
              <div
                onClick={() => {
                  navigate("/kmail");
                }}
                className="d-flex gap-3 icon-KP_03-KMail cursor-pointer menu_icon_font"
              ></div>
              <div
                onClick={() => {
                  navigate("/katchup");
                }}
                className="d-flex gap-3 icon-KP_04-Katchup cursor-pointer menu_icon_font"
              ></div>
              <div
                onClick={() => {
                  navigate("/kall");
                }}
                className="d-flex gap-3 icon-KP_05-Kall cursor-pointer menu_icon_font"
              ></div>
              <div
                onClick={() => {
                  navigate("/kdirectory");
                }}
                className="d-flex gap-3 icon-KP_06-KDirectory cursor-pointer menu_icon_font"
              ></div>
            </div>
          ) : (
            <div className="d-flex flex-column gap-4">
              <div
                // onClick={() => {
                //   navigate("/writemail");
                //   setBackColor("icon-KP_02-Write-Letter");
                // }}
                // className={`"d-flex gap-3 icon-KP_02-Write-Letter cursor-pointer" ${
                //   backColor === "icon-KP_02-Write-Letter"
                //     ? "menu_icon_font_back"
                //     : "menu_icon_font"
                // }`}
                onClick={() => {
                  navigate("/home");
                }}
                className="d-flex align-items-center gap-3 icon-KP_01-Home cursor-pointer menu_icon_font"
              >
                <span className="menu_font">Home</span>
              </div>
              <div
                onClick={() => {
                  navigate("/writemail");
                }}
                className="d-flex align-items-center gap-3 icon-KP_02-Write-Letter cursor-pointer menu_icon_font"
              >
                <span className="menu_font">Write Mail</span>
              </div>
              <div
                onClick={() => {
                  navigate("/kmail");
                }}
                className="d-flex align-items-center gap-3 icon-KP_03-KMail cursor-pointer menu_icon_font"
              >
                <span className="menu_font">KMail</span>
              </div>
              <div
                onClick={() => {
                  navigate("/katchup");
                }}
                className="d-flex align-items-center gap-3 icon-KP_04-Katchup cursor-pointer menu_icon_font"
              >
                <span className="menu_font">Katchup</span>
              </div>
              <div
                onClick={() => {
                  navigate("/kall");
                }}
                className="d-flex align-items-center gap-3 icon-KP_05-Kall cursor-pointer menu_icon_font"
              >
                <span className="menu_font">Kall</span>
              </div>
              <div
                onClick={() => {
                  navigate("/kdirectory");
                }}
                className="d-flex align-items-center gap-3 icon-KP_06-KDirectory cursor-pointer menu_icon_font"
              >
                <span className="menu_font">KDirectory</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div style={{ marginLeft: `${navWidth}%` }}>
        <Outlet />
      </div>

      {/* </div> */}
    </>
  );
};

export default Header;
