import { EndPointURL } from "./ServiceURL";

export async function PostLogin(body) {
  try {
    const response = await fetch(`${EndPointURL}/signupLogin/userLogin/`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 403) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function FetchKpostIDDetails(body) {
  try {
    const response = await fetch(
      `${EndPointURL}/signupLogin/fetchUserDetails/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 403) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function ForgetPasswordSentOTP(body) {
  try {
    const response = await fetch(
      `${EndPointURL}/common/forgotPasswordOTPOrSentKpostIDSms/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 403) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function ForgetPasswordOTPVerify(body) {
  try {
    const response = await fetch(`${EndPointURL}/common/validateOTP/`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 403) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function ForgetPasswordUpdate(body) {
  try {
    const response = await fetch(
      `${EndPointURL}/common/forgotPasswordUpdate/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 403) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}


export async function GenerateJWTToekns(body) {
  const AccessToken = localStorage.getItem(`refreshToken`);
  try {
    const response = await fetch(
      `${EndPointURL}/signupLogin/generateJWTokens/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 403) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}
