import React from "react";
import "./MessageBox.css";
import Read from "../../../../Assets/Read.png";
import UnRead from "../../../../Assets/Unread.png";

const MessageBox = ({
  name,
  profilePic,
  showRightContent,
  time,
  sub,
  copy,
  date,
  para,
  isEdited,
  seen,
  count,
  edit,
  editName,
  editName1,
  para1,
}) => {
  return (
    <div className="back-message-color p-2">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center message-name-color px-1">
          <div>
            <img src={profilePic} alt="profilephoto" width={"70%"} />
          </div>
          <div className="ft-size-15">{name}</div>
        </div>

        <div className="d-flex align-items-center gap-3">
          {showRightContent}
        </div>
      </div>
      <img src={seen ? Read : UnRead} alt="Seen" />

      <div className="d-flex align-items-center justify-content-between pb-2">
        <div className="sub_font font">Sub:{sub}</div>
        <div className="time_font font">{time}</div>
      </div>

      <div className="copy_font font pb-1">{copy}</div>

      {/* <div className="para_font font">{para}</div>
      <div className="">{isEdited}</div> */}
      <div>
        {isEdited ? (
          <div className="d-flex flex-column gap-2">
            <div className="edit_para_outline font p-1">
              <div className="edit_name_font font">
                <span className="edit_font font">{edit} : </span>
                {editName}
              </div>
              <div className="para_font font pt-1">{para}</div>
            </div>

            <div className="edit_para_outline font p-1">
              <div className="edit_name_font font d-flex align-items-center justify-content-between">
                <div className="edit_name_font1 font">{editName1}</div>
                <div className="font date_font">{date}</div>
              </div>
              <div className="para_font font pt-1">{para1}</div>
            </div>
          </div>
        ) : (
          <div className="para_font font pt-1">{para}</div>
        )}
      </div>

      <div className=" d-flex align-items-center gap-2 pt-2">
        <div className="add_font font add_back">Addressed To</div>

        <div className="d-flex justify-content-center name_font_background px-1">
          {/* <div> */}
          <img src={profilePic} alt="profilephoto" width={"10%"} />
          {/* </div> */}
          {/* <div> */}
          <span className="name_font font">{name}</span>
          {/* </div> */}
        </div>

        <div className="count_back cursor-pointer">
          <div className="count_font">{count}+</div>
        </div>
      </div>
    </div>
  );
};

MessageBox.defaultProps = {
  name: "",
  profilePic: "",
  showRightContent: "",
  time: "",
  sub: "",
  copy: "",
  date: "",
  para: "",
  isEdited: false,
  seen: false,
  count: "",
  edit: "",
  editName: "",
};
export default MessageBox;
// {seen && <img src={seen === "Read" ? Read : UnRead} alt="Seen" />}
