import React from "react";
import meesho from "../../images/meesho.png";
import flipkart from "../../images/flipkart.png";
import screen from "../../images/Screenshot_1.png";
import booking from "../../images/booking.png";
import trip from "../../images/trip.png";
import Goa from "../../images/Goa.webp";
import "./Ecommerce.css";

const Ecommerce = () => {
  const place = [
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
    {
      image: Goa,
      name: "Goa",
      property: "900 property",
    },
  ];

  return (
    <div className="height-scroll">
      <div className="comm_back p-3">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <div className="d-flex align-items-center gap-4">
            <div className="ecomm_font">KEcommerce</div>
            <div className="icon-KP_250 cursor-pointer font-size"></div>
            <div className="icon-KP_236_Edit cursor-pointer font-size"></div>
          </div>
          <div className="icon-KP_311_Maximize-Half cursor-pointer font-size"></div>
        </div>
        <div className="meesho_background p-3 mb-2">
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div>
              <img src={meesho} alt="meesho Logo" width={80} />
            </div>
            <div className="d-flex align-items-center gap-2 cursor-pointer">
              <div className="News-ViewMore">View more</div>
              <div className="icon-KP_26-Right-Arrow News-ArrowIcon"></div>
            </div>
          </div>
          <div className="dress_back p-2">
            <div className="d-flex flex-column gap-1 border">
              <div className="d-flex justify-content-center">
                <img src={screen} alt="" width={70} />
              </div>
              <div className="font">Stylish Gym Vests</div>
              <div className="font_price">
                ₹ 283 <span className="font_style">onwards</span>{" "}
              </div>
              <div className="font_free">Free Delivery</div>
              <div className="">
                <span className="font_rating">2.8</span>&nbsp;
                <span className="font_style">4 Reviews</span>
              </div>
            </div>
          </div>
        </div>
        <div className="meesho_background p-3 ">
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div>
              <img src={flipkart} alt="meesho Logo" width={80} />
            </div>
            <div className="d-flex align-items-center gap-2 cursor-pointer">
              <div className="News-ViewMore">View more</div>
              <div className="icon-KP_26-Right-Arrow News-ArrowIcon"></div>
            </div>
          </div>
          <div className="dress_back p-2">
            <img src={screen} alt="" width={70} />
          </div>
        </div>
      </div>

      <div className="comm_back p-3 mt-3">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <div className="d-flex align-items-center gap-4">
            <div className="ecomm_font">KBooking</div>
            <div className="icon-KP_250 cursor-pointer font-size"></div>
            <div className="icon-KP_236_Edit cursor-pointer font-size"></div>
          </div>
          <div className="icon-KP_311_Maximize-Half cursor-pointer font-size"></div>
        </div>
        <div className="meesho_background p-3">
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div>
              <img src={booking} alt="meesho Logo" width={100} />
            </div>
            <div className="d-flex align-items-center gap-2 cursor-pointer">
              <div className="News-ViewMore">View more</div>
              <div className="icon-KP_26-Right-Arrow News-ArrowIcon"></div>
            </div>
          </div>
          <div className="dress_back p-2">
            <div className="d-flex flex-wrap gap-2">
              {place.map((x) => (
                <div className="d-flex flex-column gap-2">
                  <div>
                    <img src={x.image} alt="" width={"85px"} />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="place_font">{x.name}</div>
                    <div className="font_free">{x.property}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="meesho_background p-3 mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <img src={trip} alt="meesho Logo" width={100} />
            </div>
            <div className="d-flex align-items-center gap-2 cursor-pointer">
              <div className="News-ViewMore">View more</div>
              <div className="icon-KP_26-Right-Arrow News-ArrowIcon"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
