import React, { useState } from "react";
import "./SearchBar.css";
import ModalComponent from "../../../common/ModalComponent/ModalComponent";
import Select from "../../../common/Select/Select";
import Button from "../../../common/Button/Button";
// import Message4 from "../../images/Message4.png";
import Person4 from "../../images/Person4.png";
// import { MultiSelect } from "react-multi-select-component";
import Input from "../../../common/Input/Input";
import ContactBar from "../../../common/ContactBar/ContactBar";

const SearchBar = ({ miniSearch }) => {
  const [ModalShow, setModalShow] = useState(false);

  const [selectValue, setSelectValue] = useState({
    Data: "",
    Message: "",
    Word: "",
    by: "",
    Content: "",
    Fromdate: "",
    Todate: "",
  });

  const handleCloseButton = (e) => {
    setSelectValue({
      Data: "",
      Message: "",
      Word: "",
      by: "",
      Content: "",
      Fromdate: "",
      Todate: "",
    });
    setResult(false);
    setModalShow(false);
  };

  const [result, setResult] = useState(false);

  const options = [
    [
      { label: "All", value: "All" },
      { label: "Name", value: "Name" },
      { label: "Reference", value: "Reference" },
      { label: "Profession", value: "Profession" },
      { label: "Subject", value: "Subject" },
      { label: "Content of Message", value: "Content of Message" },
    ],
    [
      { label: "All", value: "All" },
      { label: "Copy", value: "Copy" },
      { label: "Forward", value: "Forward" },
      { label: "Reply", value: "Reply" },
      { label: "Comment", value: "Comment" },
      { label: "Clarify", value: "Clarify" },
    ],
    [
      { label: "React", value: "React" },
      { label: "IOS Developer", value: "IOS Developer" },
    ],
    [
      { label: "All", value: "All" },
      { label: "Send", value: "Send" },
      { label: "Received", value: "Received" },
    ],
    [
      { label: "All", value: "All" },
      { label: "Audio", value: "Audio" },
      { label: "Video", value: "Video" },
      { label: "Image", value: "Image" },
      { label: "Documents", value: "Documents" },
      { label: "Messages", value: "Messages" },
    ],
  ];

  const chatsContent = [
    {
      profilePic: Person4,
      status: "Viewed",
      seen: "Read",
      name: "Srini Raghunathan",
      role: "Web Designer",
      subject: "Subject : Designing Work Status",
      msg: "Today(01.09.2022) 83 active users. With KPOST Premium, you can search for any Global KPOST User and add them to your 'Conta...",
      msgCount: "02",
      time: "13:45",
      copies: " icon-KP_203---Copies",
    },
    {
      profilePic: Person4,
      status: "NotViewed",
      seen: "Read",
      name: "Srini Raghunathan",
      role: "KPost Content Writer",
      subject: "Subject : Update your Kmail Signature",
      msg: "Setup -> Kmail -> Mail Signature KMAIL SIGN A design concept is the  core idea driving the design of a product, explained via a co...",
      msgCount: "02",
      time: "12:45",
      copies: " icon-KP_203---Copies",
    },
    {
      profilePic: Person4,
      status: "NotViewed",
      seen: "Read",
      name: "Srini Raghunathan",
      role: "KPost Content Writer",
      subject: "Subject : Update your Kmail Signature",
      msg: "Setup -> Kmail -> Mail Signature KMAIL SIGN A design concept is the  core idea driving the design of a product, explained via a co...",
      msgCount: "02",
      time: "14:45",
      copies: " icon-KP_203---Copies",
    },
    {
      profilePic: Person4,
      status: "NotViewed",
      seen: "Read",
      name: "Srini Raghunathan",
      role: "KPost Content Writer",
      subject: "Subject : Update your Kmail Signature",
      msg: "Setup -> Kmail -> Mail Signature KMAIL SIGN A design concept is the  core idea driving the design of a product, explained via a co...",
      msgCount: "02",
      time: "15:45",
      copies: " icon-KP_203---Copies",
    },
  ];

  // const searchContent = [
  //   {
  //     profilePic: Person4,
  //     status: "Viewed",
  //     seen: "Read",
  //     name: "Srini Raghunathan",
  //     role: "Web Designer",
  //     subject: "Subject : Designing Work Status",
  //     msg: "Today(01.09.2022) 83 active users. With KPOST Premium, you can search for any Global KPOST User and add them to your 'Conta...",
  //     msgCount: "02",
  //     time: "13:45",
  //     copies: " icon-KP_203---Copies",
  //   },
  // ]

  return (
    <div className="w-100">
      <div className="Search position-relative px-2 w-100">
        <div className="icon-KP_30-Search Search_icon cursor-pointer"> </div>
        <input className="Serach_input" placeholder="Search" />
        <div>
          <div
            onClick={() => setModalShow(true)}
            className={`${
              miniSearch ? "" : "display-none"
            } icon-KP_225_Advanced-Search Search_Advance_icon cursor-pointer`}
          ></div>

          <ModalComponent
            size={result && "xl"}
            show={ModalShow}
            Title={result ? "Advance Search Result" : "Advanced Search "}
            onClick={handleCloseButton}
            isGradient={false}
            Content={
              result ? (
                <div className="d-flex flex-column gap-2 ">
                  <div className="d-flex gap-3 font-size-modal mb-3">
                    <Select
                      label={"Select by Data Type"}
                      placeholder={"Select Data Type"}
                      value={selectValue.Data}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Data: e })
                      }
                      options={options[0]}
                      labelClassName={"Select_label "}
                    />
                    <Select
                      label={"Select Type of Message"}
                      placeholder={"Select Message"}
                      value={selectValue.Message}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Message: e })
                      }
                      options={options[1]}
                      isDisabled={selectValue.by ? false : true}
                      labelClassName={"Select_label"}
                    />
                    <Input
                      label={"Search By Word"}
                      placeholder={"Enter Word"}
                      value={selectValue.Word}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Word: e.target.value })
                      }
                      isDisabled={selectValue.Content ? false : true}
                      labelClassName={"Select_label"}
                      className={"input-size p-2"}
                      // options={options[2]}
                    />

                    <Select
                      label={"Select Message by"}
                      placeholder={"Select Message "}
                      value={selectValue.by}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, by: e })
                      }
                      options={options[3]}
                      isDisabled={selectValue.Data ? false : true}
                      labelClassName={"Select_label"}
                    />
                    <Select
                      label={"Select Select Content Type"}
                      placeholder={"Select Content Type "}
                      value={selectValue.Content}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Content: e })
                      }
                      options={options[4]}
                      isDisabled={selectValue.Message ? false : true}
                      labelClassName={"Select_label"}
                    />

                    <Input
                      value={selectValue.Fromdate}
                      onChange={(e) =>
                        setSelectValue({
                          ...selectValue,
                          Fromdate: e.target.value,
                        })
                      }
                      className={"p-2"}
                      label={" From"}
                      placeholder={"From"}
                      isDate={true}
                      isDisabled={selectValue.Word ? false : true}
                      labelClassName={"Select_label "}
                    />
                    <Input
                      value={selectValue.Todate}
                      onChange={(e) =>
                        setSelectValue({
                          ...selectValue,
                          Todate: e.target.value,
                        })
                      }
                      className={"p-2"}
                      label={"To"}
                      placeholder={"To"}
                      type={"date"}
                      isDate={true}
                      isDisabled={selectValue.Fromdate ? false : true}
                      labelClassName={"Select_label"}
                    />
                  </div>
                  <div className=" date-color d-flex justify-content-center ">
                    29 Sep 2023
                  </div>
                  <div className="contact-height ">
                    <div className="border-bottom-blue">
                      {chatsContent.map((contentItem, contentIndex) => (
                        <ContactBar
                          className={
                            "d-flex align-items-center justify-content-between px-3 chat-Container"
                          }
                          key={contentIndex}
                          profilePic={contentItem.profilePic}
                          status={contentItem.status}
                          seen={contentItem.seen}
                          name={contentItem.name}
                          role={contentItem.role}
                          description={contentItem.subject}
                          msg={contentItem.msg}
                          rightSideContent={
                            <>
                              <div className="recent-msgTime">
                                {contentItem.time}
                              </div>
                            </>
                          }
                        />
                      ))}
                    </div>
                    <div className=" date-color d-flex justify-content-center ">
                      28 Sep 2023
                    </div>
                    {chatsContent.map((contentItem, contentIndex) => (
                      <ContactBar
                        className={
                          "d-flex align-items-center justify-content-between px-3 chat-Container"
                        }
                        key={contentIndex}
                        profilePic={contentItem.profilePic}
                        status={contentItem.status}
                        seen={contentItem.seen}
                        name={contentItem.name}
                        role={contentItem.role}
                        description={contentItem.subject}
                        msg={contentItem.msg}
                        rightSideContent={
                          <>
                            <div className="recent-msgTime">
                              {contentItem.time}
                            </div>
                          </>
                        }
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-md-12 d-flex gap-4 justify-content-center align-items-center modal-dropdown-size">
                    <div className="col-md-6 d-flex flex-column gap-2 ">
                      <Select
                        label={"Select by Data Type"}
                        placeholder={"Select Data Type"}
                        value={selectValue.Data}
                        onChange={(e) =>
                          setSelectValue({ ...selectValue, Data: e })
                        }
                        options={options[0]}
                      />
                      <Select
                        label={"Select Type of Message"}
                        placeholder={"Select Message"}
                        value={selectValue.Message}
                        onChange={(e) =>
                          setSelectValue({ ...selectValue, Message: e })
                        }
                        options={options[1]}
                        isDisabled={selectValue.by ? false : true}
                      />

                      {/* <div>
                    <label className="Label_Font">Select Type of Message</label>
                      <MultiSelect
                        className="Dropdown_Back_Color"
                        options={options[1]}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        disableSearch={"false"}
                      />
                    </div> */}
                      <Input
                        className={"p-2"}
                        label={"Search By Word"}
                        placeholder={"Enter Word"}
                        value={selectValue.Word}
                        onChange={(e) =>
                          setSelectValue({
                            ...selectValue,
                            Word: e.target.value,
                          })
                        }
                        isDisabled={selectValue.Content ? false : true}
                        // options={options[2]}
                      />
                    </div>
                    <div className="col-md-6  d-flex flex-column gap-2">
                      <Select
                        label={"Select Message by"}
                        placeholder={"Select Message"}
                        value={selectValue.by}
                        onChange={(e) =>
                          setSelectValue({ ...selectValue, by: e })
                        }
                        options={options[3]}
                        isDisabled={selectValue.Data ? false : true}
                      />
                      <Select
                        label={"Select Select Content Type"}
                        placeholder={"Select Content Type"}
                        value={selectValue.Content}
                        onChange={(e) =>
                          setSelectValue({ ...selectValue, Content: e })
                        }
                        options={options[4]}
                        isDisabled={selectValue.Message ? false : true}
                      />
                      {/* <div>
                    <label className="Label_Font">Select Content Type</label>
                    <MultiSelect
                      className="Dropdown_Back_Color"
                      label={"Select Content Type"}
                      value={contentselect}
                      onChange={setcontentSelect}
                      placeholder="Select Data Type"
                      labelledBy="Select"
                      disableSearch={"false"}
                      options={options[4]} />
                      </div> */}
                      <div className="d-flex flex-row gap-2">
                        <Input
                          value={selectValue.Fromdate}
                          onChange={(e) =>
                            setSelectValue({
                              ...selectValue,
                              Fromdate: e.target.value,
                            })
                          }
                          className={"p-2"}
                          label={" From"}
                          placeholder={"From"}
                          isDate={true}
                          isDisabled={selectValue.Word ? false : true}
                        />
                        <Input
                          value={selectValue.Todate}
                          onChange={(e) =>
                            setSelectValue({
                              ...selectValue,
                              Todate: e.target.value,
                            })
                          }
                          className={"p-2"}
                          label={"To"}
                          placeholder={"To"}
                          type={"date"}
                          isDate={true}
                          isDisabled={selectValue.Fromdate ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      label={"Search"}
                      className={"Button_Style Button_Font w-25"}
                      isDisabled={selectValue.Todate ? false : true}
                      backgroundcolor={
                        selectValue.Todate ? "#054694" : "#4c729e"
                      }
                      onClick={() => setResult(true)}
                    />
                  </div>
                </>
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

SearchBar.defaultProps = {
  miniSearch: true,
};

export default SearchBar;
