import React from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";

import Home from "./components/auth/Home";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Profile from "./components/dashboard/Profile";
import Header from "./containers/Header";
import Katchup from "./components/Katchup/Katchup";
import Kmail from "./components/Kmail/Kmail";
import Kall from "./components/Kall/Kall";
import WriteMail from "./components/WriteMail/WriteMail";
import MainHomePage from "./components/Home/MainHomePage";
import Kdirectory from "./components/Kdirectory/Kdirectory";

import { loginSuccess, logout, Katchupcontact } from "./actions/authActions";
import { isEmpty } from "./utils/validation";
import { GenerateJWTToekns } from "./Services/Login.js";
import {
  GetMyContacts,
  GetMyUnknownContacts,
  GetMyGroup,
  GetMyUnknownGroup,
} from "./Services/Contacts.js";

const MenuRoutes = (user) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const FetchContactsData = async () => {
    let contactsData, unknownContactData, groupData, unknowngroupData;
    let Cbod = {
      lastfetchDate: null,
    };
    let responsemyc = await GetMyContacts(Cbod);
    if (responsemyc.status === "SUCCESS") {
      contactsData = responsemyc.contacts_added;
    } else {
      contactsData = [];
    }

    let responsemyunc = await GetMyUnknownContacts(Cbod);
    if (responsemyunc.status === "SUCCESS") {
      unknownContactData = responsemyunc.unknown_contact_added;
    } else {
      unknownContactData = [];
    }

    let responsemygro = await GetMyGroup(Cbod);
    if (responsemygro.status === "SUCCESS") {
      groupData = responsemygro.group_added;
    } else {
      groupData = [];
    }

    let responsemyungro = await GetMyUnknownGroup(Cbod);
    if (responsemyungro.status === "SUCCESS") {
      unknowngroupData = responsemyungro.unknown_group_added;
    } else {
      unknowngroupData = [];
    }

    dispatch(
      Katchupcontact(
        contactsData,
        unknownContactData,
        groupData,
        unknowngroupData
      )
    );
  };

  React.useEffect(() => {
    // console.log(localStorage.getItem("accessToken"),"eeeeeeeeeee");
    // alert(window.location.pathname)
    if (
      localStorage.length > 0 &&
      !isEmpty(localStorage.getItem("accessToken"))
    ) {
      // console.log(localStorage.getItem("accessToken"),"alert");
      const decodedJwt = parseJwt(localStorage.getItem("accessToken"));
      if (decodedJwt.exp * 1000 < Date.now()) {
        const RefreshToken = localStorage.getItem(`refreshToken`);
        if (RefreshToken) {
          const JWTTokenrefresh = async (token) => {
            let bod = {
              refreshToken: token,
            };
            let response = await GenerateJWTToekns(bod);
            console.log(response, "JWT TOKEN");
            if (response.status === "SUCCESS") {
              dispatch(
                loginSuccess(
                  response.data,
                  response.accessToken,
                  response.refreshToken
                )
              );
              FetchContactsData();
            } else {
              dispatch(logout());
              navigate("/login");
            }
          };
          JWTTokenrefresh(RefreshToken);
        } else {
        }
      } else {
        FetchContactsData();
        // console.log("active","tokennnnnnnnnnnnn");
      }
    } 
    else {
      dispatch(logout());
      if(window.location.pathname === "/signup" || window.location.pathname === "/login") {
      // const goToHomePage = () => navigate("/login");
      // goToHomePage();
    } else {
      navigate("/login");
    }
  }
  }, [window.location.pathname]);

  console.log(user);
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/" element={<Header />}>
          <Route path="/katchup" element={<Katchup />} />
          <Route path="/kmail" element={<Kmail />} />
          <Route path="/kall" element={<Kall />} />
          <Route path="/writemail" element={<WriteMail />} />
          <Route path="/home" element={<MainHomePage />} />
          <Route path="/kdirectory" element={<Kdirectory />} />
        </Route>
        {user !== "" ? (
          <Route path="/profile" element={<Profile user={user} />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )}
      </Routes>
    </>
  );
};

export default MenuRoutes;
