import { CONTACTS, LOGIN_SUCCESS, LOGOUT } from "../actions/authActions";
import { notEmpty, isEmpty } from "../utils/validation";

// console.log(localStorage.getItem("Authuser"), "QQQQQQQQQQQ");
// console.log(localStorage.getItem("accessToken"), "QQQQQQQQQQQ");
// console.log(localStorage.getItem("refreshToken"), "QQQQQQQQQQQ");
const initialState = {
  user: isEmpty(localStorage.getItem('Authuser')) ? '' : localStorage.getItem('Authuser'),
  accessToken: isEmpty(localStorage.getItem('accessToken')) ? '' : localStorage.getItem('accessToken'),
  refreshToken: isEmpty(localStorage.getItem('refreshToken')) ? '' : localStorage.getItem('refreshToken'),
  isAuthenticated: localStorage.getItem('isAuthenticated') === "true" || false,
  myContacts: [],
  myUnknownContacts: [],
  mygroup: [],
  myUnknownGroup: [],
};
console.log(initialState, "QQQQQQQQQQQ");
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      // Update local storage
      console.log(action,"QQQQQQQQQQQ");
      localStorage.setItem('Authuser', JSON.stringify(action.payload));
      localStorage.setItem('isAuthenticated', action.loginSuccess);
      localStorage.setItem("accessToken", action.accessToken);
      localStorage.setItem("refreshToken", action.refreshToken);
      return {
        ...state,
        user: action.payload,
        isAuthenticated: action.loginSuccess,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      };
    case LOGOUT:
      // Clear local storage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("Authuser");
      // localStorage.setItem('Authuser', '');
      localStorage.setItem("isAuthenticated", "false");
      // window.location = '/';
      return {
        ...state,
        user: "",
        isAuthenticated: action.loginSuccess,
        accessToken: "",
        refreshToken: "",
        myContacts: [],
        myUnknownContacts: [],
        mygroup: [],
        myUnknownGroup: [],
      };
    case CONTACTS:
      return {
        ...state,
        myContacts: action.myContacts,
        myUnknownContacts: action.myUnknownContacts,
        myGroup: action.myGroup,
        myUnknownGroup: action.myUnknownGroup,
      };
    default:
      return state;
  }
};

export default authReducer;
