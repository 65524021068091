import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Recent from "../Recent/Recent";
import Contact from "../Contact/Contact";

const KatchupPage = () => {
  return (
    <div>
      <div className="Backgroundclr_inside">
        <div className="Katchup_Label ">
          <div className=" icon-KP_101-Katchup-Notification Katchup_Icon d-flex align-items-center p-2 justify-content-center gap-2">
            <span className="Katchup_Name">Katchup</span>
          </div>
        </div>
        <Tabs
          defaultActiveKey="Recent"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="Recent" title="Recent">
            <span>
              <Recent />
            </span>
          </Tab>
          <Tab eventKey="Contact" title="Contact">
            <Contact />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default KatchupPage;
