import React from "react";
import ContactBar from "../../../common/ContactBar/ContactBar";
import profile from "../../images/Person1.png";
import profile2 from "../../images/Person2.png";
import "./KatchupMessage.css";
import MessageBox from "../MessageBox/MessageBox";
import WriteMessage from "../WriteMessage/WriteMessage";

const KatchupMessage = () => {
  return (
    <>
      <div className="comm_back p-3">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <div className="ecomm_font">Katchup</div>
          <div className="d-flex align-items-center gap-3">
            <div className="icon-KP_30-Search cursor-pointer font-size"></div>
            <div className="icon-KP_136-Close cursor-pointer font-size"></div>
          </div>
        </div>
        <div>
          <ContactBar
            profilePic={profile}
            name={"Anandbabu Gandeepan"}
            role={"Software Engineer"}
            description={"IOS Developer"}
            className={"background-color-katchup p-1 border-bottom"}
            status={"NotViewed"}
          />
        </div>

        <div className="background-color">
          <div className="d-flex justify-content-between align-items-center w-100 pad-katchup">
            <div className="d-flex Recent-LabelDate">
              <div className="Recent-date">28-Oct-2023</div>
              <div className="Recent-count">05</div>
            </div>
            <div className="d-flex gap-2 mar-katchup">
              <div className="icon-KP_02-Write-Letter icon-font-katchup fs-6 cursor-pointer"></div>
              <div className="icon-KP_03-KMail icon-font-katchup fs-6 cursor-pointer"></div>
              <div className="icon-KP_05-Kall icon-font-katchup fs-6 cursor-pointer"></div>
              <div className="icon-KP_59-More-Lined icon-font-katchup fs-6 cursor-pointer"></div>
            </div>
          </div>

          {/* Message Box  */}
          <div className="px-3 py-2 d-flex flex-column gap-2 message_scroll_height">
            {/* <div className="msg_height "> */}
            <div>
              <MessageBox
                profilePic={profile}
                name={"Anandbabu Gandeepan"}
                sub={"Design Idea"}
                time={"05:30"}
                copy={"Recieved Copy"}
                para={
                  "A design concept is the  core idea driving the design of a product, explained via a collection of sketches. A design concept is the  core idea driving the design of a product, explained via a collection of sketches"
                }
                count={"51"}
                seen={true}
                showRightContent={
                  <div className="d-flex align-items-center gap-3">
                    <div className="icon-KP_203---Copies message-icon-color cursor-pointer"></div>
                    <div className="icon-KP_43-Fav message-icon-color cursor-pointer"></div>
                    <div className="icon-KP_73-rply message-icon-color cursor-pointer"></div>
                  </div>
                }
              />
            </div>

            <div>
              <MessageBox
                profilePic={profile2}
                name={"Ramalinga Raju"}
                sub={"Design Idea"}
                time={"05:20"}
                copy={"Sented Copy"}
                para={
                  "With KPOST Premium, you can search for any Global KPOST User and add them to your 'Contacts' to start communicating with them through KMail, Katchup and Kall"
                }
                edit={"Edited"}
                editName={"Ramalinga Raju"}
                editName1={"Ramalinga Raju to Anandbabu Gandeepan"}
                para1={
                  "With KPOST Premium, you can search for any Global KPOST User and add them to your 'Contacts' to start communicating with them through KMail, KATCHUP and KALL"
                }
                isEdited={true}
                count={"51"}
                seen={true}
                date={"26 Apr 2022 | 16:24"}
                showRightContent={
                  <div className="d-flex align-items-center gap-3">
                    <div className="icon-KP_43-Fav message-icon-color cursor-pointer"></div>
                    <div className="icon-KP_72-bell message-icon-color cursor-pointer"></div>
                  </div>
                }
              />
            </div>
            {/* </div> */}
          </div>
          {/* Message Box  */}
        </div>

        <div className="fixed_write_msg">
          <WriteMessage />
        </div>
      </div>
    </>
  );
};

export default KatchupMessage;
