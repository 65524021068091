// components/Login.js
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSuccess, logout, Katchupcontact } from "../../actions/authActions";
import CryptoJS from "crypto-js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import KLogo from "../../Assets/Kpost_Logo.png";
import Carousel from "../common/Carousel/Carousel";
import Kmail from "../../Assets/images/signup/KMail.png";
import Katchup from "../../Assets/images/signup/Katchup.png";
import Kall from "../../Assets/images/signup/Kall.png";
import KDirectory from "../../Assets/images/signup/Kdirectory.png";
import Profile from "../../Assets/Profile.png";
import Input from "../common/Input/Input";
import Select from "../common/Select/Select";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import Button from "../common/Button/Button";

// Log and Sri
//Api Services
import { GetAllCountry } from "../../Services/SignUp.js";
import {
  PostLogin,
  ForgetPasswordUpdate,
  ForgetPasswordOTPVerify,
  ForgetPasswordSentOTP,
  FetchKpostIDDetails,
} from "../../Services/Login.js";
import {
  GetMyContacts,
  GetMyUnknownContacts,
  GetMyGroup,
  GetMyUnknownGroup
} from "../../Services/Contacts.js";
import { device_uuid, DeviceWithversion, timestamp } from "../../utils/device";
import { SignInOTPVerify } from "../../utils/validation.js";


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);
  const [countryAll, setcountryAll] = useState([]);
  const [country, setCountry] = useState("");
  const [LoginUserName, setLoginUserName] = useState("");
  const [forgotKpostId, setFogotKpostId] = useState("");
  const [formData, setFormData] = useState({ userLoginID: "", password: "" });
  const [ForgetpwdDetails, setForgetpwdDetails] = useState({
    mobileNumber: "",
    countryID: "",
  });
  const [signInBoolean, setsignInBoolean] = useState({
    LoginId: true,
    LoginModal: false,
    forgetPassword: false,
    otpSection: false,
  });
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const initialOtp = ["", "", "", "", "", ""];
  const [otpNumber, setOtpNumber] = useState(initialOtp);
  const [disableResend, setDisableResend] = useState(true);
  const [updatePassword, setUpdatePassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [passwordError, setPasswordError] = useState('');
  const validatePassword = (inputPassword) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(inputPassword);
  };

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setDisableResend(false);
      clearInterval(countdown);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    dispatch(logout());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Geolocation error:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported in this browser.");
    }
  }, []);

  const handleModalClose = () => {
    setsignInBoolean({ ...signInBoolean, LoginModal: false });
    setUpdatePassword({
      newPassword: "",
      confirmNewPassword: "",
    });
  }

  const handleSubmit = async () => {
    // if (formData.userLoginID !== "") {
    const temp = {
      kpostID: formData.userLoginID,
    };
    let response = await FetchKpostIDDetails(temp);
    if (response.status.toLowerCase() === "success") {
      setLoginUserName(response.data.firstName + " " + response.data.lastName);
      setsignInBoolean({ ...signInBoolean, LoginId: false });
      setFogotKpostId(response.data.kpostID)
    } else {
      toast.error("The KPOST ID / Mobile Number doesn't exist.");
    }
    // }
  };
  const getTempObject = (userLoginID) => {
    return !isNaN(userLoginID)
      ? { mobileNumber: userLoginID, requestType: "PASSWORD" }
      : { kpostID: userLoginID, requestType: "PASSWORD" };
  };

  const handleForgetPassword = async () => {
    let temp = getTempObject(formData.userLoginID);
    let response = await ForgetPasswordSentOTP(temp);
    if (response.status.toLowerCase() === "success") {
      setsignInBoolean({
        ...signInBoolean,
        LoginModal: true,
        forgetPassword: true,
        otpSection: true,
      });
      setFormData({ ...formData, password: "" });
      setCommonSuccessState(response);
    } else {
      toast.error("Please contact the Admin Team..");
    }
  };
  const setCommonSuccessState = (response) => {
    toast.success(response.message);
    setForgetpwdDetails({
      mobileNumber: response.mobileNumber,
      countryID: response.countryID,
    });
    setTimer(30);
    setDisableResend(true);
  };
  const sendOTP = async () => {
    let temp = getTempObject(formData.userLoginID);
    let response = await ForgetPasswordSentOTP(temp);
    if (response.status.toLowerCase() === "success") {
      setCommonSuccessState(response);
    } else {
    }
  };
  const handleVerifyOTP = async (valotp) => {
    // setOtpNumber(initialOtp);
    try {
      const otp = Array.isArray(valotp) ? valotp.join("") : valotp;
      const bod = {
        otp: otp,
        countryID: ForgetpwdDetails.countryID,
        mobileNumber: ForgetpwdDetails.mobileNumber,
        sendDate: Date.now(),
      };
      const response = await ForgetPasswordOTPVerify(bod);

      if (response?.status === "SUCCESS") {
        //  setOtpValidationStatus(true);
        toast.success(response?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setsignInBoolean({
          ...signInBoolean,
          otpSection: false,
        });
      } else {
        toast.error('Invalid OTP. Please enter the correct code', {
          position: toast.POSITION.TOP_RIGHT
        });
        // setOtpValidationStatus(false);
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
    }
  };

  const FetchContactsData = async () => {
    let contactsData, unknownContactData, groupData, unknowngroupData;
    let Cbod = {
        lastfetchDate: null
    }
    let responsemyc = await GetMyContacts(Cbod);
    if(responsemyc.status === "SUCCESS"){
      contactsData = responsemyc.contacts_added
    } else {
      contactsData = [];
    }

    let responsemyunc = await GetMyUnknownContacts(Cbod);
    if(responsemyunc.status === "SUCCESS"){
      unknownContactData = responsemyunc.unknown_contact_added
    } else {
      unknownContactData = [];
    }

    let responsemygro = await GetMyGroup(Cbod);
    if(responsemygro.status === "SUCCESS"){
      groupData = responsemygro.group_added
    } else {
      groupData = [];
    }

    let responsemyungro = await GetMyUnknownGroup(Cbod);
    if(responsemyungro.status === "SUCCESS"){
      unknowngroupData = responsemyungro.unknown_group_added
    } else {
      unknowngroupData = [];
    }

    dispatch(
    Katchupcontact(contactsData,unknownContactData,groupData,unknowngroupData)
    );


    }

  const handleLogin = async () => {
    let bod = {
      loginRO: {
        countryID: country.countryID,
        password: formData.password,
      },
      kpostID: formData.userLoginID,
      sessionID: device_uuid,
      deviceType: "Web",
      deviceIdentity_primary: device_uuid,
      deviceIdentity_secondary: DeviceWithversion,
      login_lattitude: latitude,
      login_longitude: longitude,
      oneSignal_Key: "oneSignalKey",
      logintime: timestamp,
    };
    let response = await PostLogin(bod);
    if (response.status.toLowerCase() === "success") {
      dispatch(
        loginSuccess(response.data, response.accessToken, response.refreshToken)
      );
      FetchContactsData();
      navigate("/profile");
      toast.success(response?.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(response?.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const CarouselData = [
    {
      Img: Kmail,
      class: "icon-KP_03-KMail",
      Title: "Kmail",
      Text: "All new Mailing experience with fresh approach. No more drab inbox and outboxes. Convenient contact-wise folders for you to have comprehensive view of all mails of a contact.",
    },
    {
      Img: Katchup,
      class: "icon-KP_04-Katchup",
      Title: "Katchup",
      Text: "An instant digital messaging module with numerous patented features and seamless integration with Mailing and Calling.",
    },
    {
      Img: Kall,
      class: "icon-KP_05-Kall",
      Title: "Kall",
      Text: "Kall offers a set of new experiences with innovative IP voice, IP video, Mobile, Landline, Duo and Facetime calling - All from KPOST.",
    },
    {
      Img: KDirectory,
      class: "icon-KP_06-KDirectory",
      Title: "KDirectory",
      Text: "Global KPOST Directory that connects you to any other KPOST user from individual users, Business users, Institutional Users and Government Users across the globe.",
    },
  ];

  const passwordChange = async () => {
    if (updatePassword.newPassword === updatePassword.confirmNewPassword) {
      const body = {
        kpostID: forgotKpostId,
        forgotPassword: updatePassword.confirmNewPassword,
      };
  
      try {
        let response = await ForgetPasswordUpdate(body);
        if (response.status.toLowerCase() === "success") {
          setsignInBoolean({
            ...signInBoolean,
            LoginModal: false,
            forgetPassword: false,
            otpSection: false,
            LoginId: true,
          });
          toast.success(response?.message);
          setUpdatePassword({
            ...updatePassword,
            newPassword: "",
            confirmNewPassword: "",
          });
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to update password', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } else {
      toast.error('Passwords do not match', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
  

  const CountryGetAll = async () => {
    let response = await GetAllCountry();
    if (response.status === "SUCCESS") {
      if (response.data.length > 0) {
        let dataOPtion = response.data.map((e) => {
          return {
            ...e,
            label: `+${e.countryCode}     ${e.countryName}`,
            value: `${e.countryID}`,
          };
        });
        setcountryAll(dataOPtion);
      }
    }
  };

  useEffect(() => {
    CountryGetAll();
  }, []);

  // Logesh and sri

  // const handleLogin = async () => {
  //   try {
  //     // Perform the login API call here and store the response data
  //     const response = await fetch(
  //       "https://testapi.kampus.org.in/login/checklogin",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formData),
  //       }
  //     );

  //     if (response.status === 200) {
  //       const user = await response.json();
  //       console.log(user, "USER DETAILS");
  //       // Dispatch the user data to Redux
  //       // <Navigate to="/profile" />

  //       dispatch(loginSuccess(user.value));
  //       navigate("/profile");
  //     } else {
  //       // Handle login error
  //       console.error("Login failed");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //   }
  // };

  return (
    // <div>
    //   <h2>Login</h2>
    //   <input
    //     type="text"
    //     placeholder="Username"
    //     value={formData.userLoginID}
    //     onChange={(e) => setFormData({ ...formData, userLoginID: e.target.value })}
    //   />
    //   <input
    //     type="password"
    //     placeholder="Password"
    //     value={formData.password}
    //     onChange={(e) => setFormData({ ...formData, password: e.target.value })}
    //   />
    //   <button onClick={handleLogin}>Login</button>
    // </div>
    <div>
      <div className="col-md-12 Container Back-Image d-flex justify-content-around align-items-center">
        <div className="col-md-5">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex align-items-center gap-3">
              <div className="Img_Border d-flex align-items-center">
                <img src={KLogo} width="100%" alt="" />
              </div>
              <div className="Kpost-Font">Welcome to KPOST</div>
            </div>

            <div>
              <Carousel Carousel={CarouselData} />
            </div>
          </div>
        </div>

        {/* <div className="col-md-2"></div> */}
        {/* Logesh */}

        <div className="col-md-5 px-5">
          <div className="Signup_Main">
            <div className="d-flex flex-column">
              <div className="category-label nuntio-font d-flex align-items-center justify-content-between">
                Login
                {!signInBoolean.LoginModal && !signInBoolean.LoginId && (
                  <BsArrowLeft
                    className={"cursor-pointer"}
                    onClick={() => {
                      setsignInBoolean({
                        ...signInBoolean,
                        LoginId: true,
                        LoginModal: false,
                      });
                    }}
                    size="25"
                  />
                )}
              </div>
              <div className="category-sub-label nuntio-font">
                Enter Login Details
              </div>
            </div>

            {signInBoolean.LoginId ? (
              <div className="d-flex flex-column justify-content-center nuntio-font Login_height ">
                <div className="d-flex flex-column gap-4 mt-3">
                  <div className="d-flex flex-column gap-1">
                    <Select
                      label={"Country"}
                      placeholder={"Choose Country"}
                      options={countryAll}
                      value={country}
                      onChange={(e) => {
                        setCountry(e);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <Input
                      label={"KPOST ID / Mobile Number"}
                      value={formData.userLoginID}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          userLoginID: e.target.value,
                        })
                      }
                      placeholder={"Enter KPOST ID / Mobile number"}
                      isDisabled={country ? false : true}
                    />
                    {/* <div className="Login-needHelp ">
                      <span className="Cursor_pointer">Need Help?</span>
                    </div> */}
                  </div>

                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="Button_Style Button_Font"
                    disabled={formData.userLoginID ? false : true}
                    backgroundcolor={
                      formData.userLoginID ? "#054694" : "#4c729e"
                    }
                    color={"#fffff"}
                    label={"Submit"}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column gap-3 my-3 UserLogIN-Box p-3">
                <div className="d-flex gap-2 align-items-center Login-profile">
                  <img src={Profile} alt="Profile" width={60} height={60} />

                  <div className="d-flex flex-column">
                    <div className="Login-userName">{LoginUserName}</div>
                    <div className="Login-userId">{formData.userLoginID}</div>
                  </div>
                </div>

                <div className="d-flex flex-column gap-4">
                  <div>
                    <Input
                      label={"Password"}
                      type={"password"}
                      value={formData.password}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                      isPassword={true}
                      className={"input_Icon-Padding"}
                    />
                    <div
                      className="Login-needHelp "
                    >
                      <span
                        className="Cursor_pointer"
                        onClick={() => {
                          setOtpNumber(initialOtp);
                          handleForgetPassword();
                        }}
                      >
                        Forgot Password ?
                      </span>
                    </div>
                  </div>

                  <Button
                    className="Button_Style Button_Font"
                    disabled={formData.password ? false : true}
                    backgroundcolor={formData.password ? "#054694" : "#4c729e"}
                    color={"#fffff"}
                    label={"Login"}
                    onClick={() => {
                      handleLogin();
                    }}
                  />

                  <div className=" Login_otherId">
                    <span
                      className="Cursor_pointer"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          userLoginID: "",
                          password: "",
                        });
                        setsignInBoolean({
                          ...signInBoolean,
                          LoginId: true,
                          LoginModal: false,
                        });
                      }}
                    >
                      Login with Other KPOST ID
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="Login_NeedAc">
              Need an Account?{" "}
              <span
                onClick={() => {
                  setFormData({ ...formData, userLoginID: "", password: "" });
                  navigate("/signup");
                }}
                className="Cursor_pointer Login_need_Account"
              >
                Sign Up
              </span>
            </div>
          </div>
        </div>

        <Modal show={signInBoolean.LoginModal} dialogClassName="modal-50w" centered>
          {/* {!signInBoolean.forgetPassword && (
            <div className="d-flex flex-column">
              <div>
                <Modal.Header className="d-flex justify-content-between align-items-center">
                  <Modal.Title>Login for Your KPOST Channels</Modal.Title>
                  <AiOutlineClose
                    className="Icon_Style"
                    onClick={() => {
                      handleModalClose();
                    }}
                  />
                </Modal.Header>
              </div>

              <div className="p-3 Modal_Style">
                <div className="Modal_Div d-flex flex-column gap-3">
                  <div className="Login-ModalLabel">Your Channels</div>

                  <div className="d-flex justify-content-between Login_Back_Color">
                    <div className="d-flex gap-2 align-items-center">
                      <img src={Profile} alt="Profile" height={40} width={40} />
                      <div>
                        <div className="Login-ModalProfile">Ramalinga Raju</div>
                        <div className="Login-ModalProfile">(04)</div>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center Login-ModalLogin Cursor_pointer"
                      onClick={() => {
                        setsignInBoolean({
                          ...signInBoolean,
                          LoginId: false,
                          LoginModal: false,
                        });
                      }}
                    >
                      <span>Login</span>&nbsp;
                      <MdArrowForwardIos size={12} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {signInBoolean.forgetPassword && (
            <div className="d-flex flex-column">
              <div>
                <Modal.Header className="d-flex justify-content-between align-items-center">
                  <Modal.Title>Forgot Password</Modal.Title>
                  <AiOutlineClose
                    className="Icon_Style"
                    onClick={() => {
                      handleModalClose();
                    }}
                  />
                </Modal.Header>
              </div>
              {signInBoolean.otpSection ? (
                <div className="p-3 Modal_Style">
                  <div className="Modal_Div d-flex flex-column gap-2">
                    <div className="Otp_Font">
                      {!isNaN(formData.userLoginID) ? (
                        <div>
                          6-Digit OTP has been sent to your registered recovery
                          mobile number ( {formData.userLoginID.slice(0, 2)}
                          {Array(6).fill("x").join("")}
                          {formData.userLoginID.slice(-2)}).
                        </div>
                      ) : (
                        <div>
                          6-Digit OTP has been sent to your registered mobile
                          number ( {`${formData.userLoginID}`} ).
                        </div>
                      )}
                    </div>

                    <div className="d-flex gap-4">
                      {otpNumber.map((item, index) => (
                        <div key={index}>
                          <Input
                            id={`input_${index}`}
                            className={"text-center"}
                            value={otpNumber[index]}
                            onChange={(e) => {
                              SignInOTPVerify(
                                index,
                                e,
                                setOtpNumber,
                                otpNumber
                              );
                              const newOtpNumber1 = [...otpNumber];
                              newOtpNumber1[index] = e.target.value;
                              setOtpNumber(newOtpNumber1);
                              if (newOtpNumber1.filter(Boolean).length === 6) {
                                handleVerifyOTP(newOtpNumber1.join(''));
                              }
                            }}
                            maxLength={"1"}
                            placeholder={"0"}
                          />
                        </div>
                      ))}
                    </div>
                    {timer !== null && (
                      <div className="d-flex justify-content-end Otp_Font">
                        <span
                          className={`cursor-pointer Color ${disableResend ? "disabled" : ""
                            }`}
                          onClick={disableResend ? null : () => sendOTP()}
                          style={{
                            pointerEvents: disableResend ? "none" : "auto",
                            color: disableResend
                              ? "rgb(100 139 185)"
                              : "#054694",
                          }}
                        >
                          Resend OTP {timer > 0 && `(${timer}s)`}
                        </span>
                      </div>
                    )}
                    {/* <div>
                      <Button
                        onClick={() => {
                          handleVerifyOTP();
                        }}
                        className="Button_Font Button_Style mt-2 w-100"
                        disabled={
                          otpNumber.filter((x) => x !== "").length === 6
                            ? false
                            : true
                        }
                        backgroundcolor={
                          otpNumber.filter((x) => x !== "").length === 6
                            ? "#054694"
                            : "#4c729e"
                        }
                        color={"#fffff"}
                        label={"Submit"}
                      />
                    </div> */}
                  </div>
                </div>
              ) : (
                <div className="p-3 Modal_Style">
                  <div className="Modal_Div d-flex flex-column gap-3">
                    <div className="Login-userName">
                      Enter Your New Password
                    </div>

                    {/* <div className="d-flex flex-column gap-2">
                      <Input
                        label={"New Password"}
                        type={"password"}
                        value={updatePassword.newPassword}
                        onChange={(e) => {
                          setUpdatePassword({
                            ...updatePassword,
                            newPassword: e.target.value,
                          });
                        }}
                        isPassword={true}
                        className={"input_Icon-Padding"}
                      />

                      <Input
                        label={"Confirm New Password"}
                        type={"password"}
                        value={updatePassword.confirmNewPassword}
                        onChange={(e) => {
                          setUpdatePassword({
                            ...updatePassword,
                            confirmNewPassword: e.target.value,
                          });
                        }}
                        isPassword={true}
                        className={"input_Icon-Padding"}
                        isDisabled={updatePassword.newPassword ? false : true}
                      />
                    </div> */}

                    <div className="d-flex flex-column gap-2">
                      <Input
                        label={"New Password"}
                        type={"password"}
                        value={updatePassword.newPassword}
                        onChange={(e) => {
                          const newPassword = e.target.value;
                          setUpdatePassword({
                            ...updatePassword,
                            newPassword: newPassword,
                          });
                          if (newPassword.length >= 6) {
                            if (!validatePassword(newPassword)) {
                              setPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character.');
                            } else if (newPassword !== updatePassword.confirmNewPassword && updatePassword.confirmNewPassword !== "") {
                              setPasswordError('New Password and Confirm New Password do not match');
                            } else {
                              setPasswordError('');
                            }
                          } else {
                            setPasswordError('Password must be at least 6 characters long.');
                          }
                        }}
                        isPassword={true}
                        className={"input_Icon-Padding"}
                      />
                      {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}

                      <Input
                        label={"Confirm New Password"}
                        type={"password"}
                        value={updatePassword.confirmNewPassword}
                        onChange={(e) => {
                          const confirmNewPassword = e.target.value;
                          setUpdatePassword({
                            ...updatePassword,
                            confirmNewPassword: confirmNewPassword,
                          });
                          if (confirmNewPassword !== updatePassword.newPassword) {
                            setPasswordError('New Password and Confirm New Password do not match');
                          } else {
                            setPasswordError('');
                          }
                        }}
                        isPassword={true}
                        className={"input_Icon-Padding"}
                        isDisabled={!validatePassword(updatePassword.newPassword)}
                      />
                    </div>

                    <div>
                      <Button
                        onClick={passwordChange}
                        className="Button_Font Button_Style mt-2 w-100"
                        disabled={
                          updatePassword.confirmNewPassword && updatePassword.newPassword === updatePassword.confirmNewPassword ? false : true
                        }
                        backgroundcolor={
                          updatePassword.confirmNewPassword && updatePassword.newPassword === updatePassword.confirmNewPassword
                            ? "#054694"
                            : "#4c729e"
                        }
                        color={"#fffff"}
                        label={"Submit"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal>

        {/* Logesh */}
      </div>
    </div>
  );
};

export default Login;
