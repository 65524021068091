import React from "react";
import BBCLogo from "../../images/BBC-Logo.png";
import HinduLogo from "../../images/TheHindu-Logo.png";
import News1 from "../../images/New1.png";
import News2 from "../../images/New2.png";
import News3 from "../../images/New3.png";
import "./News.css";

const News = () => {
  const NewsList = [
    [
      {
        Editor: BBCLogo,
        Content: [
          {
            img: News1,
            para: "Cyclone Ian batters South Carolina in second US landfall Cyclone Ian batters South Carolina in ...",
            date: "22 Sep 2022",
          },
          {
            img: News2,
            para: `Faridkot: An Indian maharaja and a 'mystery' will Faridkot: An Indian maharaja and a 'mystery' will ...`,
            date: "22 Sep 2022",
          },
        ],
      },
    ],

    [
      {
        Editor: HinduLogo,
        Content: [
          {
            img: News3,
            para: "Cyclone Ian batters South Carolina in second US landfall Cyclone Ian batters South Carolina in ...",
            date: "22 Sep 2022",
          },
        ],
      },
    ],
  ];

  return (
    <div className="w-100 News-Container p-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-4">
          <span className="ecomm_font">KNews</span>
          <span className="icon-KP_250 cursor-pointer font-size"></span>
          <span className="icon-KP_236_Edit cursor-pointer font-size"></span>
        </div>
        <span className=" icon-KP_311_Maximize-Half cursor-pointer font-size"></span>
      </div>

      <div className="d-flex flex-column align-items-center gap-3 New-contentScroll pt-2">
        {NewsList.map((item, index) => (
          <div key={index} className="d-flex flex-column gap-3 News-List">
            {item.map((x, editorIndex) => (
              <>
                <div
                  key={editorIndex}
                  className="d-flex align-items-center justify-content-between"
                >
                  <img src={x.Editor} alt="EditorLogo" />
                  <div className="News-ViewMore cursor-pointer">
                    View more&nbsp;
                    <span className=" icon-KP_26-Right-Arrow News-ArrowIcon"></span>
                  </div>
                </div>

                {x.Content.map((content, contentIndex) => (
                  <div
                    key={contentIndex}
                    className="d-flex align-items-center gap-3"
                  >
                    <img
                      src={content.img}
                      width={"40%"}
                      alt="NewsImage"
                      className="News-contentImg"
                    />
                    <div className="d-flex flex-column gap-1">
                      <div className="News-Content">{content.para}</div>
                      <div className="News-Date">{content.date}</div>
                    </div>
                  </div>
                ))}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
