import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/authActions';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // alert()
    // Perform your logout logic here, such as clearing tokens or user data.
    dispatch(logout());
    // Redirect to the desired page after logout.
    navigate('/login'); // Redirect to the login page after logout.
  };
  // useEffect(() => {
  // handleLogout()
  // },[]);

  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default Logout;
