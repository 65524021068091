import React from "react";
import ReactSelect from "react-select";

const Select = ({
  label,
  className,
  labelClassName,
  placeholder,
  value,
  onChange,
  errorMessage,
  options,
  isDisabled,
}) => {
  return (
    <div className="d-flex flex-column w-100 gap-1">
      <label className={` ${labelClassName} ? ${labelClassName} : Label_Font `}>
        {label}
      </label>
      <ReactSelect
        value={value === "" ? null : value}
        onChange={onChange}
        className={"Dropdown_Back_Color ${className}"}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        labelledBy="Select"
      />
      <div className="err-msg">{errorMessage}</div>
    </div>
  );
};

Select.defaultProps = {
  value: "",
  onChange: () => {},
  errorMessage: "",
  label: "",
  placeholder: "",
  options: [],
  isDisabled: false,
  className: "",
};

export default Select;
