import React from "react";
import "./Recent.css";
import SearchBar from "../SearchBar/SearchBar";
import Date from "../Date/Date";
import RecentMessage from "../RecentMessage/RecentMessage";
import UnopenedMessage from "../UnOpenedMessage/UnOpenedMessage";

function Recent() {
  return (
    <div>
      <span className="d-flex flex-row align-items-center justify-content-between px-2">
        <SearchBar />
        <Date />
      </span>
      <span>
        <UnopenedMessage />
        <RecentMessage />
      </span>
    </div>
  );
}

export default Recent;
