import React, { useEffect, useState } from "react";
import Person1 from "../../images/Person1.png";
import Message1 from "../../images/Message1.png";
import Message2 from "../../images/Message2.png";
import Message4 from "../../images/Message4.png";
import Message3 from "../../images/Message3.png";
// import Select from "../../../common/Select/Select";
import ContactBar from "../../../common/ContactBar/ContactBar";
import "./ContactList.css";
import ModalComponent from "../../../common/ModalComponent/ModalComponent";
import Input from "../../../common/Input/Input";
import Button from "../../../common/Button/Button";
import gmail from "../../images/gmail.svg";
import linkedIn from "../../images/linkedin.png";
import instagram from "../../images/instagram.png";
import whatsapp from "../../images/whatsapp.png";
import telegram from "../../images/telegram.png";
import facebook from "../../images/facebook.png";
import { useNavigate } from "react-router-dom";
import { WindowHeight } from "../../../../utils/device";

const ContactList = () => {
  const [mapList, setMapList] = useState([]);
  const [personalContact, setPersonalContact] = useState(false);
  const [arrow, setArrow] = useState(true);
  const [contactIcon, setContactIcon] = useState(false);
  const [threeDotIcon, setThreeDotIcon] = useState(false);
  const [addRefModal, setAddRefModal] = useState(false);
  const [addInviteModal, setAddInviteModal] = useState(false);
  const [addReference, setAddReference] = useState("");

  const ContactLists = [
    {
      category: "My Contacts",
      subCategory: [
        {
          type: "Personal",
          contact: [
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              msgCount: "03",
              category: "MyContacts-Personal",
              icon: "icon-KP_271_Add-Other-Mail-Accounts",
            },
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              msgCount: "03",
              category: "MyContacts-Personal",
              icon: "icon-KP_271_Add-Other-Mail-Accounts",
            },
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              msgCount: "03",
              category: "MyContacts-Personal",
              icon: "icon-KP_271_Add-Other-Mail-Accounts",
            },
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              msgCount: "03",
              category: "MyContacts-Personal",
              icon: "icon-KP_271_Add-Other-Mail-Accounts",
            },
          ],
        },
        {
          type: "Personal",
          contact: [
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              ref: "IOS Developer",
              msgCount: "03",
              category: "MyContacts-Personal",
            },
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              ref: "IOS Developer",
              msgCount: "03",
              category: "MyContacts-Personal",
            },
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              ref: "IOS Developer",
              msgCount: "03",
              category: "MyContacts-Personal",
            },
            {
              profilePic: Person1,
              status: "NotViewed",
              name: "Anandbabu Gandeepan",
              role: "Joint Managing Director",
              ref: "IOS Developer",
              msgCount: "03",
              category: "MyContacts-Personal",
            },
          ],
        },
        {
          type: "Business",
          contact: [
            {
              profilePic: Message1,
              status: "NotViewed",
              name: "Apple Showroom",
              role: "Joint Managing Director",
              ref: "Ramakrishnan Arunkumar",
              msgCount: "03",
              category: "MyContacts-Business",
            },
            {
              profilePic: Message1,
              status: "Red-Circle",
              name: "Apple Showroom",
              role: "Joint Managing Director",
              ref: "Ramakrishnan Arunkumar",
              msgCount: "03",
              category: "MyContacts-Business",
            },
            {
              profilePic: Message1,
              status: "NotViewed",
              name: "Fiat Showroom",
              role: "TN NZ Marketing Manager",
              ref: "Hariharan Shanmugam",
              msgCount: "01",
              category: "MyContacts-Business",
            },
          ],
        },
        {
          type: "Institution",
          contact: [
            {
              profilePic: Message2,
              status: "NotViewed",
              name: "Chennai Public School",
              role: "Gpl puram Maths Teacher",
              ref: "Rajkumar VinothKumar",
              msgCount: "01",
              category: "MyContacts-Institution",
            },
            {
              profilePic: Message2,
              status: "NotViewed",
              name: "Chennai Public School",
              role: "Gpl puram Maths Teacher",
              ref: "Rajkumar VinothKumar",
              msgCount: "01",
              category: "MyContacts-Institution",
            },
            {
              profilePic: Message2,
              status: "NotViewed",
              name: "Chennai Public School",
              role: "Gpl puram Maths Teacher",
              ref: "Rajkumar VinothKumar",
              msgCount: "01",
              category: "MyContacts-Institution",
            },
          ],
        },
        {
          type: "Government",
          contact: [
            {
              profilePic: Message4,
              status: "NotViewed",
              name: "Chennai Corporation Office",
              role: "Gpl.puram Deputy Comm (Edu)",
              ref: "Shena Devakumar",
              msgCount: "03",
              category: "MyContacts-Government",
            },
            {
              profilePic: Message4,
              status: "NotViewed",
              name: "Chennai Corporation Office",
              role: "Gpl.puram Deputy Comm (Edu)",
              ref: "Shena Devakumar",
              msgCount: "03",
              category: "MyContacts-Government",
            },
            {
              profilePic: Message4,
              status: "NotViewed",
              name: "Chennai Corporation Office",
              role: "Gpl.puram Deputy Comm (Edu)",
              ref: "Shena Devakumar",
              msgCount: "03",
              category: "MyContacts-Government",
            },
          ],
        },
        {
          type: "Groups",
          contact: [
            {
              profilePic: Message3,
              status: "NotViewed",
              name: "Baskar Friends Group",
              role: "Baskar Thambi Durai",
              ref: "15 Members",
              msgCount: "04",
              category: "MyContacts-Groups",
            },
            {
              profilePic: Message3,
              status: "NotViewed",
              name: "Baskar Friends Group",
              role: "Baskar Thambi Durai",
              ref: "15 Members",
              msgCount: "04",
              category: "MyContacts-Groups",
            },
          ],
        },
      ],
    },
  ];

  const DropdownList = [
    {
      name: "Personal",
      count: "31",
    },
    {
      name: "Business",
      count: "07",
    },
    {
      name: "Institution",
      count: "05",
    },
    {
      name: "Government",
      count: "07",
    },
  ];

  const otherApplication = [
    {
      src: gmail,
      name: "Gmail",
      link: "https://mail.google.com",
    },
    {
      src: whatsapp,
      name: "WhatsApp",
      link: "https://web.whatsapp.com/",
    },
    {
      src: linkedIn,
      name: "Linkedin",
      link: "https://in.linkedin.com/",
    },
    {
      src: instagram,
      name: "Instagram",
      link: "https://www.instagram.com/",
    },
    {
      src: telegram,
      name: "Telegram",
      link: "https://web.telegram.org/a/",
    },
    {
      src: facebook,
      name: "Facebook",
      link: "https://www.facebook.com/",
    },
  ];

  const Navigate = useNavigate();

  const openApplication = (link) => {
    window.open(`${link}`, "_blank");
  };

  const HandleSubmit = () => {
    setAddReference("");
    setAddRefModal(false);
  };

  useEffect(() => {
    setMapList(ContactLists);
  }, []);

  return (
    <div className="w-100 px-2">
      <div className="background-color pt-2"
      style={{
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px"
      }}
      >
        <div className="d-flex align-items-center justify-content-between px-3">
          <div
            className="contact-background-style d-flex align-items-center justify-content-between cursor-pointer"
            onClick={() => {
              setPersonalContact(!personalContact);
              setArrow(!arrow);
              setContactIcon(false);
              setThreeDotIcon(false);
            }}
          >
            <div className="d-flex align-items-center pc-font">
              Personal Contacts{" "}
              <ul>
                <li>31</li>
              </ul>
            </div>

            {arrow ? (
              <div className="icon-KP_23-Up-Arrow pc-font"></div>
            ) : (
              <div className="icon-KP_22-Down-Arrow-22 pc-font"></div>
            )}
          </div>

          <div className="d-flex align-items-center gap-4">
            <div
              onClick={() => {
                setContactIcon(!contactIcon);
                setPersonalContact(false);
                setThreeDotIcon(false);
              }}
              className="icon-KP_107-User-Add contact_Icon cursor-pointer icon-back-contact"
            ></div>
            <div
              onClick={() => {
                setContactIcon(false);
                setPersonalContact(false);
                setThreeDotIcon(!threeDotIcon);
              }}
              className=" icon-KP_144---More-Vertical contact_Icon cursor-pointer icon-back-contact"
            ></div>
          </div>
        </div>

        {personalContact && (
          <div className="position-absolute dropdown-width mt-1">
            <div className="d-flex align-items-center pc-font-dropdown">
              My Contacts
              <ul>
                <li>50</li>
              </ul>
            </div>

            <div className="d-flex flex-column border-bottom">
              {DropdownList.map((x) => (
                <div className="d-flex align-items-center py-2 dropdown-name-font">
                  {x.name}
                  <ul>
                    <li>{x.count}</li>
                  </ul>
                </div>
              ))}
            </div>

            <div className="d-flex align-items-center pc-font-dropdown py-2 border-bottom">
              My Groups
              <ul>
                <li>05</li>
              </ul>
            </div>

            <div className="d-flex align-items-center pc-font-red-dropdown py-2">
              Unknown Contacts
              <ul>
                <li>09</li>
              </ul>
            </div>

            <div className="d-flex flex-column border-bottom">
              {DropdownList.map((x) => (
                <div className="d-flex align-items-center px-0 py-2 dropdown-name-font">
                  {x.name}
                  <ul>
                    <li>{x.count}</li>
                  </ul>
                </div>
              ))}
            </div>

            <div className="d-flex align-items-center pc-font-red-dropdown py-2">
              Unknown Groups
              <ul>
                <li>03</li>
              </ul>
            </div>
          </div>
        )}

        {contactIcon && (
          <div className="position-absolute contact-dropdown-width mt-1">
            <div className="d-flex flex-column">
              <div
                onClick={() => {
                  setAddInviteModal(true);
                  setContactIcon(false);
                }}
                className="dropdown-name-font border-bottom py-2 px-3"
              >
                Invite Contacts
              </div>
              <div
                onClick={() => Navigate("/kdirectory")}
                className="dropdown-name-font border-bottom py-2 px-3"
              >
                KDirectory
              </div>
              <div className="dropdown-name-font py-2 px-3">Bulk Message</div>
            </div>
          </div>
        )}

        {threeDotIcon && (
          <div className="position-absolute contact-dropdown-width mt-1">
            <div className="d-flex-flex-column">
              <div className="dropdown-name-font border-bottom py-2 px-3">
                Mobile Contacts
              </div>
              <div className="dropdown-name-font py-2 px-3">
                Other Applications
              </div>
            </div>
          </div>
        )}

        <div className="pt-1">
          {" "}
          {mapList.map((item, index) => (
            <div key={index} className="contact-height"
            style={{
              height: WindowHeight - 355 + "px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px"
            }}
            >
              {/* <div className="contact_category py-1">{item.category}</div> */}
              {item.subCategory.map((subItem, subIndex) => (
                <div
                  className="d-flex flex-column gap-2 contact_border"
                  key={subIndex}
                >
                  {/* <div className="contact_category py-1 ">{subItem.type}</div> */}
                  {subItem.contact.map((contactItem, contactIndex) => (
                    <div key={contactIndex}>
                      <ContactBar
                        profilePic={contactItem.profilePic}
                        status={contactItem.status}
                        name={contactItem.name}
                        role={contactItem.role}
                        description={contactItem.ref}
                        rightSideContent={
                          <div className="d-flex align-items-center gap-2">
                            <div
                              onClick={() => {
                                setAddRefModal(true);
                              }}
                              className={`${contactItem.icon} plus-icon cursor-pointer`}
                            ></div>
                            <div className="d-flex">
                              {/* {item.category === "Unknown" && "true"} */}
                              <div className="contact_msgCount">
                                {contactItem.msgCount}
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div>
          <ModalComponent
            show={addRefModal}
            Title={"Add Reference"}
            onClick={() => {
              setAddRefModal(false);
            }}
            isGradient={false}
            size={"md"}
            Content={
              <div className="">
                <div className="border-bottom pb-2 ref-font">
                  Dharani Govindaraju has been successfully added to the KPOST
                  Contacts list.
                </div>
                <div className="pt-2 ref-font-style">
                  Add Reference To Dharani Govindaraju
                </div>
                <div className="ref-para-font pt-2 pb-3">
                  You can give this contact a unique identity to set it apart
                  from others, such as "relationship," "nick name," "area of
                  work," "profession," "designation," "subject," etc.
                </div>
                <div className="d-flex flex-column f-12">
                  <div className="ref-font-style">Add Reference</div>
                  <div>
                    <Input
                      placeholder={"Add Reference"}
                      value={addReference}
                      onChange={(e) => {
                        setAddReference(e.target.value);
                      }}
                      className={"p-2"}
                    />
                  </div>
                </div>
                <div className="pt-3">
                  <Button
                    className="Button_Font Button_Style w-100 cursor-pointer"
                    disabled={addReference ? false : true}
                    backgroundcolor={addReference ? "#054694" : "#4c729e"}
                    color={"#fffff"}
                    label={"Save"}
                    onClick={HandleSubmit}
                  />
                </div>
              </div>
            }
          />
        </div>

        <div>
          <ModalComponent
            show={addInviteModal}
            Title={"Invite Contacts"}
            onClick={() => setAddInviteModal(false)}
            isGradient={false}
            Content={
              <div className="d-flex justify-content-between align-items-center gap-4">
                {otherApplication.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex flex-column align-items-center gap-2 cursor"
                    onClick={() => openApplication(item.link)}
                  >
                    <img
                      src={item.src}
                      alt="Application"
                      className="Application-image"
                    />
                    <div className="Application-label">{item.name}</div>
                  </div>
                ))}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ContactList;
