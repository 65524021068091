import React, { useState } from "react";
import Person1 from "../../images/Person1.png";
import Person2 from "../../images/Person2.png";
import Person3 from "../../images/Person3.png";
import Person4 from "../../images/Person4.png";
import ContactCircle from "../../../common/ContactCircle/ContactCircle";
import "./frequentlyAccessed.css";

const FrequentlyAccessed = () => {
  const [showContact, setShowContact] = useState(true);

  const FrequentList = [
    {
      profilePic: Person1,
      name: "Vijay Govindaraja",
      role: "Technical Head",
      status: "Viewed",
    },
    {
      profilePic: Person2,
      name: "Anandbabu",
      role: "Technical Head",
      status: "NotViewed",
    },
    {
      profilePic: Person3,
      name: "Vijayakumar ",
      role: "Technical Head",
      status: "NotViewed",
    },
    {
      profilePic: Person4,
      name: "Baskar Thambi",
      role: "Technical Head",
      status: "Red-Circle",
    },
  ];
  return (
    <div className="w-100 px-2 pt-2">
      <div
        className={`background-color border-radius  ${
          showContact && "border-bottom-fa"
        }`}
      >
        <div className="d-flex justify-content-between align-items-center py-2 px-3">
          <div className="Frequent-Label ">Frequently Accessed</div>
          <div
            className={`Frequent-Arrow cursor ${
              showContact
                ? "icon-KP_132-UP-arrow UpArrow"
                : "icon-KP_22-Down-Arrow-22 Frequent-Arrow DownArrow"
            }`}
            onClick={() => setShowContact(!showContact)}
          ></div>
        </div>

        {showContact ? (
          <div className="d-flex align-items-center justify-content-between">
            <span className=" icon-KP_25-Left-Arrow Unopened-Arrow LeftArrow ml-2"></span>

            <div className="d-flex align-items-center justify-content-start px-1 gap-2 w-100">
              {FrequentList.map((item, index) => (
                <ContactCircle
                  key={index}
                  profilePic={item.profilePic}
                  name={item.name}
                  role={item.role}
                  status={item.status}
                />
              ))}
            </div>

            <span className="icon-KP_26-Right-Arrow Unopened-Arrow RightArrow"></span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FrequentlyAccessed;
