import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../Date/Date.css";

function App() {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const formattedSelectedDay = selectedDate.toLocaleDateString("en-US", {
    day: "2-digit",
  });

  return (
    <div onClick={toggleCalendar} className="Date_alignment cursor-pointer">
      <div className="icon-KP_13-Kalender text-white Inside_Date">
        <div className=" Calender_icon">{formattedSelectedDay}</div>
      </div>
      {showCalendar && (
        <div className="CalenderPostion">
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </div>
      )}
    </div>
  );
}

export default App;
