import React from "react";
import Person1 from "../../images/Person1.png";
import Person2 from "../../images/Person2.png";
import Person3 from "../../images/Person3.png";
import Person4 from "../../images/Person4.png";
import "./UnOpenedMessage.css";
import ContactCircle from "../../../common/ContactCircle/ContactCircle";

const UnopenedMessage = () => {
  const UnopenedList = [
    {
      profilePic: Person1,
      name: "Vijay ... ",
      role: "Technical Head",
      count: "03",
      status: "Viewed",
    },
    {
      profilePic: Person2,
      name: "Anand ...",
      role: "Technical Head",
      count: "03",
      status: "NotViewed",
    },
    {
      profilePic: Person3,
      name: "kumar ...",
      role: "Technical Head",
      count: "03",
      status: "NotViewed",
    },
    {
      profilePic: Person4,
      name: "Baskar ...",
      role: "Technical Head",
      count: "03",
      status: "Red-Circle",
    },
  ];
  return (
    <div className="w-100 px-2 pt-2">
      <div className="background-color border-radius">
        <div className="d-flex justify-content-between align-items-center py-2 px-3">
          <div className="Unopened-Label ">Unopened Messages</div>
          <div className="icon-KP_23-Up-Arrow Unopened-Arrow UpArrow cursor-pointer"></div>
        </div>

        <div className="d-flex align-items-center justify-content-between border_bottom">
          <span className=" icon-KP_25-Left-Arrow Unopened-Arrow LeftArrow cursor-pointer"></span>
          {UnopenedList.map((item, index) => (
            <ContactCircle
              key={index}
              profilePic={item.profilePic}
              count={item.count}
              name={item.name}
              role={item.role}
              status={item.status}
            />
          ))}
          <span className="icon-KP_26-Right-Arrow Unopened-Arrow RightArrow cursor-pointer"></span>
        </div>
      </div>
    </div>
  );
};

export default UnopenedMessage;
