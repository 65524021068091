import React from "react";
import Person4 from "../../images/Person4.png";
import Message4 from "../../images/Message4.png";
import "./RecentMessage.css";
import ContactBar from "../../../common/ContactBar/ContactBar";
import { WindowHeight } from "../../../../utils/device";
const RecentMessage = () => {
  const MessageList = [
    [
      {
        date: "28 Sep 2022",
        count: "04",
        chatsContent: [
          {
            profilePic: Person4,
            status: "NotViewed",
            seen: "UnRead",
            name: "Senthil Kumar",
            role: "Software Engineer",
            subject: "Subject: Google New Search",
            msg: "Cyclone batters South second US land...",
            msgCount: "02",
            time: "14:04",
            copies: " icon-KP_203---Copies",
            attach: " icon-KP_36-Attach",
          },
          {
            profilePic: Person4,
            status: "NotViewed",
            seen: "Read",
            name: "Senthil Kumar",
            role: "Software Engineer",
            subject: "Subject: Google New Search",
            msg: "Cyclone batters South second US land...",
            msgCount: "01",
            time: "14:04",
            copies: " icon-KP_203---Copies",
            attach: " icon-KP_36-Attach",
          },
          {
            profilePic: Person4,
            status: "NotViewed",
            seen: "UnRead",
            name: "Senthil Kumar",
            role: "Software Engineer",
            subject: "Subject: Google New Search",
            msg: "Cyclone batters South second US land...",
            msgCount: "02",
            time: "14:04",
            copies: " icon-KP_203---Copies",
            attach: " icon-KP_36-Attach",
          },
          {
            profilePic: Person4,
            status: "NotViewed",
            seen: "Read",
            name: "Senthil Kumar",
            role: "Software Engineer",
            subject: "Subject: Google New Search",
            msg: "Cyclone batters South second US land...",
            msgCount: "01",
            time: "14:04",
            copies: " icon-KP_203---Copies",
            attach: " icon-KP_36-Attach",
          },
        ],
      },

      {
        date: "27 Sep 2022",
        count: "03",
        chatsContent: [
          {
            profilePic: Message4,
            status: "Viewed",
            seen: "Read",
            name: "Srini Raghunathan",
            role: "KPost Content Writer",
            subject: "Subject: Web UI Screens",
            msg: "Cyclone Ian batters South Carolina in second US land...",
            msgCount: "02",
            time: "13:45",
            copies: " icon-KP_203---Copies",
          },
        ],
      },
    ],
  ];
  return (
    <>
      <div className="w-100 px-2">
        <div>
          {MessageList.map((item, index) => (
            <div
              key={index}
              className="d-flex flex-column Inside_Scroll background-color"
              style={{
                height: WindowHeight - 290 + "px",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px"
              }}
            >
              {item.map((item, index) => (
                <>
                  <div className="d-flex Recent-LabelDate" key={index}>
                    <div className="Recent-date">{item.date}</div>
                    <div className="Recent-count">{item.count}</div>
                  </div>

                  <div
                    onClick={() => {
                      // setKatchupMessage(true);
                    }}
                  >
                    {item.chatsContent.map((contentItem, contentIndex) => (
                      <ContactBar
                        className={
                          "d-flex align-items-center justify-content-between px-3 chat-Container"
                        }
                        key={contentIndex}
                        profilePic={contentItem.profilePic}
                        status={contentItem.status}
                        seen={contentItem.seen}
                        name={contentItem.name}
                        role={contentItem.role}
                        description={contentItem.subject}
                        msg={contentItem.msg}
                        rightSideContent={
                          <>
                            <div className="d-flex align-items-center gap-3">
                              <div className="recent-MsgCount">
                                {contentItem.msgCount}
                              </div>
                              <div className="recent-msgTime">
                                {contentItem.time}
                              </div>
                            </div>

                            <div
                              className={`${contentItem.copies} recent-Copies cursor`}
                            ></div>
                            <div
                              className={`${contentItem.attach} recent-Attach cursor`}
                            ></div>
                          </>
                        }
                      />
                    ))}
                  </div>
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RecentMessage;
