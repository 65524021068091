// production End Point
// export const EndPointURL = "https://devapi2.kpostindia.com/v2";

// Development End Point
export const EndPointURL = "https://devapi2.kpostindia.com/v2"; // Server
export const otpEndPointURL = "http://192.168.2.24:8080/KPOSTWebservice/v2"; // otp env



// export const EndPointURL = "http://192.168.2.24:8989/v2"; // local
