import React, { useState } from 'react';
// npm install uuidv4
import { v4 as uuid } from "uuid";
// npm install react-device-detect
import {
    isMobile,
    isTablet,
    isBrowser,
    browserName,
    browserVersion,
  } from "react-device-detect";

export const device_uuid = uuid();
export const device_name = isMobile ? "Mobile" : isTablet ? "Tablet" : isBrowser ? "Desktop" : "Unknown";
export const DeviceWithversion = device_name + "-" + browserName + "-" + browserVersion;
export const timestamp = Date.now();
export const WindowHeight = window.innerHeight - 55;

  
  


