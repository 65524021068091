import React from "react";
import "./Diary.css";
import AdvertisementImg from "../../images/Advertisement.png";

const Diary = () => {
  return (
    <>
      <div className="w-100 Dairy-Container p-3">
        <div className="d-flex align-items-center justify-content-between pb-3">
          <div className="d-flex align-items-center gap-4">
            <span className="ecomm_font">Diary</span>
            <span className=" icon-KP_45-Add cursor-pointer font-size"></span>
            <span className="icon-KP_236_Edit cursor-pointer font-size"></span>
          </div>
          <span className=" icon-KP_311_Maximize-Half cursor-pointer font-size"></span>
        </div>

        <div className="d-flex align-items-center justify-content-around meesho_background p-3">
          <div className="d-flex align-items-center Diary_back">
            {/* <span className="Dairy-SelectLabel">Today</span>
            <span className=" icon-KP_131-Down-Arrow Dairy-SelectIcon"></span> */}
            <span>
              <select className="border-none cursor-pointer">
                <option>Today</option>
                <option>Weekly</option>
                <option>Monthly</option>
              </select>
            </span>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className="Diary-Percentage">0%</div>
            <div className="Diary-Description">
              You have <span className="text-success">8 Tasks</span> to do
              today!{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-3 pb-3">
        <img
          src={AdvertisementImg}
          alt="Advertisement"
          className="Advertisement_Img"
        />
      </div>
    </>
  );
};

export default Diary;
