import React from "react";
import "./ContactCircle.css";

const ContactCircle = ({
  profilePic,
  status,
  count,
  name,
  role,
  key,
  onClick,
}) => {
  return (
    <div
      className="d-flex flex-column align-items-center gap-1 cursor"
      key={key}
      onClick={onClick}
    >
      <div className="Img">
        <img
          src={profilePic}
          alt="ProfilePic"
          className={`${status} Circle-Border`}
        />
        {count ? (
          <span className="Msg-Count">
            <span className="Msg-Count-Font">{count}</span>
          </span>
        ) : null}
      </div>

      <div className="UserName">{name}</div>
      <div className="Role">{role}</div>
    </div>
  );
};

ContactCircle.defaultProps = {
  profilePic: "",
  status: "",
  onClick: () => {},
  count: "",
  name: "",
  role: "",
  key: "",
};

export default ContactCircle;
