import React, { useRef, useState } from "react";
import Input from "../../../common/Input/Input";
import "./WriteMessage.css";
import Triangle from "../../images/PostImage.svg";
import Button from "../../../common/Button/Button";
import ModalComponent from "../../../common/ModalComponent/ModalComponent";
import { toast } from "react-toastify";

const WriteMessage = () => {
  const [katchupMessage, setKatchupMessage] = useState({
    subject: "",
    message: "",
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [resize, setResize] = useState("Normal");
  const [showAttachment, setShowAttachment] = useState(false);

  const handleSelectedFile = (e) => {
    const files = Array.from(e.target.files);
    if (files) {
      const updatedFiles = files.map((file) => {
        return { file, caption: "" };
      });
      setSelectedFiles([...selectedFiles, ...updatedFiles]);
      console.log([selectedFiles]);
      setAttachmentModal(true);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleAttachmentModalDone = () => {
    setAttachmentModal(false);
    console.log(selectedFiles);
    setShowAttachment(true);
  };

  const fileInputRef = useRef(null);

  const handleFileClick = () => {
    const fileInput = fileInputRef.current;
    fileInput.click();
  };

  const handleCaption = (index, e) => {
    let tempArr = [...selectedFiles];
    tempArr[index].caption = e.target.value;
    setSelectedFiles(tempArr);
  };

  const handleKatchupSubmit = () => {
    const katchupPost = {
      subject: katchupMessage.subject,
      message: katchupMessage.message,
      attachments: [...selectedFiles],
    };
    console.log(katchupPost);
    toast.success("Message sent successfully");
    setSelectedFiles([]);
    setKatchupMessage({
      subject: "",
      message: "",
    });
  };

  return (
    <>
      {/* <div className="w-100 writeMsg_mainDiv d-flex flex-column justify-content-between"> */}
      <div
        className={`w-100 writeMsg_mainDiv d-flex flex-column justify-content-between ${
          resize === "FullScreen" &&
          "fullscreen_back_color ecomm_font px-3 py-0"
        }`}
      >
        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="writeMsg_label">Write Message</div>
            {resize === "Normal" ? (
              <div
                onClick={() => setResize("Resize")}
                className="icon-KP_310_Maximize-Full cursor-pointer font-size"
              ></div>
            ) : resize === "Resize" ? (
              <div className="d-flex align-items-center gap-3">
                <div
                  onClick={() => setResize("FullScreen")}
                  className="icon-KP_313 cursor-pointer font-size"
                ></div>
                <div
                  onClick={() => setResize("Normal")}
                  className="icon-KP_136-Close MsgClose_icon cursor-pointer font-size"
                ></div>
              </div>
            ) : (
              <div className="d-flex align-items-center gap-3">
                <div
                  onClick={() => setResize("Normal")}
                  className="icon-KP_312_Minimize cursor-pointer"
                ></div>
                <div
                  onClick={() => setResize("Normal")}
                  className="icon-KP_136-Close MsgClose_icon cursor-pointer font-size"
                ></div>
              </div>
            )}
          </div>

          <div>
            <Input
              value={katchupMessage.subject}
              onChange={(e) =>
                setKatchupMessage({
                  ...katchupMessage,
                  subject: e.target.value,
                })
              }
              placeholder={"Subject"}
              className={"MsgInput_style mb-2"}
            />

            <textarea
              className={` w-100 Input_Style Input_Back_Color MsgInput_style writeMsg_msg 
                        ${
                          resize === "Resize"
                            ? "resize"
                            : resize === "FullScreen"
                            ? "fullScreen"
                            : null
                        }`}
              placeholder="Write Message"
              value={katchupMessage.message}
              onChange={(e) =>
                setKatchupMessage({
                  ...katchupMessage,
                  message: e.target.value,
                })
              }
            ></textarea>
          </div>

          <div>
            {resize === "FullScreen" && (
              <div>
                <div className="writeMsg_label">
                  Attachments &nbsp;
                  {selectedFiles.length !== 0 &&
                    `• ${selectedFiles.length}`.padStart(2, "0")}
                </div>

                <div className="fullScreen_attachmentScrollDiv">
                  {selectedFiles.length === 0 ? (
                    <div className="attachment_noFiles">No files selected</div>
                  ) : (
                    <div className="d-flex flex-column gap-2">
                      {selectedFiles.map((fileObj, index) => (
                        <div
                          key={index}
                          className="bg-white p-2 d-flex align-items-center justify-content-between Attachment_section"
                        >
                          <div className="d-flex gap-3 align-items-center">
                            <div className="icon-KP_36-Attach pt-1 fullScreen_attachmentIcon font-size"></div>

                            <div className="d-flex flex-column ">
                              <div className="fullScreen_attachmentName">
                                {fileObj.file.name}&nbsp;&nbsp;
                                <span className="fullScreen_attachmentSize">
                                  {(fileObj.file.size / 1000).toFixed(1)}
                                  &nbsp;KB
                                </span>
                              </div>
                              <div className="attachmentCaption">
                                {fileObj.caption}
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={() => handleRemoveFile(index)}
                            className="icon-KP_136-Close fullScreen_closeIcon cursor font-size"
                          ></div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-4 align-items-center icon_size font-size">
              <label>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleSelectedFile}
                  id="fileInput"
                  style={{ display: "none" }}
                  multiple
                />
                {selectedFiles.length !== 0 && showAttachment ? (
                  <div className="d-flex align-items-center attachment_countBorder">
                    <span className="icon-KP_36-Attach cursor-pointer attachment_countBg"></span>
                    <span className="attachment_count">
                      {`${selectedFiles.length}`.padStart(2, "0")}
                    </span>
                  </div>
                ) : (
                  <span className="icon-KP_36-Attach cursor-pointer"></span>
                )}
              </label>

              <div className="icon-KP_3161 cursor-pointer font-size"></div>
              <div className="icon-KP_226_Emoji cursor-pointer font-size"></div>
              <div className="icon-KP_227_Secret-Message cursor-pointer font-size"></div>
              <div className="icon-KP_229_Copies1 cursor-pointer font-size"></div>
              <div className="icon-KP_53-Mic-on cursor-pointer font-size"></div>
            </div>

            <Button
              onClick={() => {
                handleKatchupSubmit();
              }}
              className="writeMsg_sendBtn"
              disabled={katchupMessage.message ? false : true}
              backgroundcolor={katchupMessage.message ? "#054694" : "#4c729e"}
              color={"#fffff"}
              label={
                <div className="d-flex gap-1 justify-content-start align-items-center">
                  <img src={Triangle} alt="Triangle" height={20} />
                  <div className="mt-1">Post</div>
                </div>
              }
            />
          </div>
        </div>
      </div>

      <ModalComponent
        size={"md"}
        show={attachmentModal}
        Title={"Attachments"}
        onClick={() => {
          setAttachmentModal(false);
        }}
        Content={
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-2 Attachment_ScrollDiv">
              {selectedFiles.map((fileObj, index) => (
                <div
                  key={index}
                  className="bg-white px-4 py-3 d-flex align-items-center justify-content-between Attachment_section"
                >
                  <div className="d-flex flex-column gap-1 w-75">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="icon-KP_36-Attach pt-1 Attachment_Icon font-size"></div>
                      <div className="Attachment_fileName">
                        {fileObj.file.name}&nbsp;&nbsp;
                        <span className="Attachment_fileSize">
                          {(fileObj.file.size / 1000).toFixed(1)}&nbsp;KB
                        </span>
                      </div>
                    </div>
                    <Input
                      className={"addCaption"}
                      placeholder="Add Caption"
                      value={fileObj.caption}
                      onChange={(e) => handleCaption(index, e)}
                      type={"text"}
                      maxLength={35}
                    />
                  </div>

                  <div
                    onClick={() => handleRemoveFile(index)}
                    className="icon-KP_136-Close Attachment_closeIcon cursor font-size"
                  ></div>
                </div>
              ))}
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <Button
                label={"ADD"}
                className="px-3 py-1 writeMsg_sendBtn"
                onClick={handleFileClick}
              />
              <Button
                label={"Done"}
                className="px-3 py-1 writeMsg_sendBtn"
                onClick={handleAttachmentModalDone}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default WriteMessage;
