import React from "react";

const Button = ({
  label,
  onClick,
  className,
  disabled,
  color,
  backgroundcolor,
}) => {
  return (
    <button
      style={{
        backgroundColor: backgroundcolor,
        color: color,
      }}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

Button.defaultProps = {
  label: "",
  onClick: () => {},
  className: "",
  disabled: false,
  color: "#fffff",
  backgroundcolor: "#054694",
};

export default Button;
