import React from "react";

const Kdirectory = () => {
  return (
    <div>
      <div className="col-md-12 d-flex align-items-center background_color p-2 overall-height">
        KDirectory
      </div>
    </div>
  );
};

export default Kdirectory;
