import React from "react";
import "./Katchup.css";
import Ecommerce from "./components/ECommerce/Ecommerce";
import Knews from "./components/Overall/Knews";
import KatchupPage from "./components/KKatch/KatchupPage";
import KatchupMessage from "./components/KatchupMessage/KatchupMessage";
import { WindowHeight } from "../../utils/device";

function Katchup() {
  return (
    <>
      <div className="col-md-12 d-flex align-items-center background_color p-2 overall-height"
      style={{
        height : WindowHeight + "px",
      }}>
        <div className="col-md-4 height-scroll"
        style={{
        height : WindowHeight - 20 + "px",
        backgroundColor: "#fff"
        }}
        >
          <KatchupPage />
        </div>
        <div className="col-md-4 px-2 height-scroll"
        style={{
        height : WindowHeight - 20 + "px",
        // backgroundColor: "#fff"
        }}
        >
          {/* <Knews /> */}
          <KatchupMessage />
        </div>
        <div className="col-md-4 height-scroll"
        style={{
        height : WindowHeight - 20 + "px",
        // backgroundColor: "#fff"
        }}
        >
          <Ecommerce />
        </div>
      </div>
    </>
  );
}

export default Katchup;
