import React from 'react';
import { useSelector } from 'react-redux';
import { userProf, isAuthenticated, LocalSecretKeys, userAccToken } from '../../selectors/authSelectors';
import Logout from '../auth/Logout';

const Profile = () => {
  const CredsValues = useSelector((state) => state);
  console.log(CredsValues,"USER DETAILS");
  // console.log(LocalSecretKeys,"USER DETAILS");

  return (
    <div>
      <Logout />
      <h2>User Profile</h2>
      <p>Name: {userProf(CredsValues)}</p>
      <p>Authenticated: {String(isAuthenticated(CredsValues))}</p>
      <p>Secret Key: {String(LocalSecretKeys)}</p>
      <p>Access token: {String(userAccToken(CredsValues))}</p>
    </div>
  );
};

export default (Profile);
