import React, { useState } from "react";
import ModalComponent from "../../../common/ModalComponent/ModalComponent";
import Select from "../../../common/Select/Select";
import Button from "../../../common/Button/Button";
import "./AdvnacedSearch.css";

function AdvancedSearch() {
  const [ModalShow, setModalShow] = useState(false);

  const [selectValue, setSelectValue] = useState({
    Vertical: "",
    Name: "",
    Profession: "",
    Reference: "",
    Postal: "",
    Department: "",
    Designation: "",
    Role: "",
  });

  const handleCloseButton = (e) => {
    setSelectValue({
      Data: "",
      Message: "",
      Word: "",
      by: "",
      Content: "",
      Fromdate: "",
      Todate: "",
    });
    setModalShow(false);
  };

  const handleClearInput = () => {
    console.log(selectValue);
    handleCloseButton();
  };

  const [vertical, setVertical] = useState({
    Business: false,
    Institution: false,
  });

  const handleVerticalChange = (event) => {
    setSelectValue({ ...selectValue, Vertical: event });
    console.log(selectValue.Vertical);

    if (event.label === "Business") {
      setVertical({ ...vertical, Business: true, Institution: false });
    } else if (event.label === "Institution") {
      setVertical({ ...vertical, Business: false, Institution: true });
    } else {
      setVertical({ ...vertical, Business: false, Institution: false });
    }
  };

  const options = [
    [
      { label: "All", value: "All" },
      { label: "Personal", value: "Personal" },
      { label: "Business", value: "Business" },
      { label: "Institution", value: "Institution" },
      { label: "Government", value: "Government" },
    ],
    [
      { label: "React", value: "React" },
      { label: "IOS Developer", value: "IOS Developer" },
      { label: "React", value: "React" },
      { label: "IOS Developer", value: "IOS Developer" },
      { label: "React", value: "React" },
    ],
    [
      { label: "sree", value: "Sree" },
      { label: "Babu", value: "Babu" },
      { label: "Logesh", value: "Logesh" },
      { label: "Kalai", value: "Kalai" },
      { label: "Ramesh", value: "Ramesh" },
    ],
    [
      { label: "React", value: "React" },
      { label: "IOS Developer", value: "IOS Developer" },
      { label: "React", value: "React" },
      { label: "IOS Developer", value: "IOS Developer" },
      { label: "React", value: "React" },
    ],
    [
      { label: "626001", value: "626001" },
      { label: "626002", value: "626002" },
      { label: "626003", value: "626003" },
      { label: "626004", value: "626004" },
      { label: "626005", value: "626005" },
    ],
    [
      { label: "BE", value: "BE" },
      { label: "B.Tech", value: "B.Tech" },
      { label: "MCA", value: "MCA" },
      { label: "MSC", value: "MSC" },
      { label: "M.Tech", value: "M.Tech" },
    ],
  ];

  return (
    <>
      <div
        className="Date_alignment cursor-pointer"
        onClick={() => setModalShow(true)}
      >
        <div className="icon-KP_225_Advanced-Search text-white Inside_Date"></div>
      </div>

      <ModalComponent
        show={ModalShow}
        Title={"Advanced Search"}
        onClick={handleCloseButton}
        isGradient={false}
        Content={
          <>
            <div className="col-md-12 d-flex flex-column gap-4 justify-content-center align-items-center modal-dropdown-size">
              <div className="col-md-4 d-flex flex-row gap-5 w-100">
                <span className="Adavnced-dropdown-width ">
                  <Select
                    label={"KPOST Vertical"}
                    placeholder={"Select KPOST Vertical"}
                    value={selectValue.Vertical}
                    onChange={handleVerticalChange}
                    options={options[0]}
                    noOptionsMessage={null}
                  />
                </span>
              </div>
              {vertical.Business ? (
                <div className="col-md-12 d-flex flex-column gap-2 justify-content-center align-items-center modal-dropdown-size">
                  <div className="col-md-4 d-flex flex-row gap-5 w-100">
                    <Select
                      label={"Name"}
                      placeholder={"Enter Name"}
                      value={selectValue.Name}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Name: e })
                      }
                      options={options[2]}
                      isDisabled={selectValue.Vertical ? false : true}
                    />
                    <Select
                      label={"Profession"}
                      placeholder={"Enter/Select Profession"}
                      value={selectValue.Profession}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Profession: e })
                      }
                      options={options[1]}
                      isDisabled={selectValue.Name ? false : true}
                    />
                  </div>
                  <div className="col-md-4 d-flex flex-row gap-5 w-100">
                    <Select
                      label={"Department"}
                      placeholder={"Enter/Select Department"}
                      value={selectValue.Department}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Department: e })
                      }
                      options={options[1]}
                      isDisabled={selectValue.Profession ? false : true}
                    />
                    <Select
                      label={"Designation"}
                      placeholder={"Enter/Select Designation"}
                      value={selectValue.Designation}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Designation: e })
                      }
                      options={options[5]}
                      isDisabled={selectValue.Department ? false : true}
                    />
                  </div>
                  <div className="col-md-4 d-flex flex-row gap-5 w-100">
                    <Select
                      label={"Role"}
                      placeholder={"Enter/Select Role"}
                      value={selectValue.Role}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Role: e })
                      }
                      options={options[1]}
                      isDisabled={selectValue.Designation ? false : true}
                    />
                    <Select
                      label={"Postal Pin Code"}
                      placeholder={"Enter Postal Pin Code"}
                      value={selectValue.Postal}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Postal: e })
                      }
                      options={options[1]}
                      isDisabled={selectValue.Role ? false : true}
                    />
                  </div>
                </div>
              ) : vertical.Institution ? (
                <div className="col-md-12 d-flex flex-column gap-2 justify-content-center align-items-center modal-dropdown-size">
                  <div className="col-md-6 d-flex flex-row gap-5 w-100">
                    <Select
                      label={"Name"}
                      placeholder={"Enter Name"}
                      value={selectValue.Name}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Name: e })
                      }
                      options={options[2]}
                      isDisabled={selectValue.Vertical ? false : true}
                    />

                    <Select
                      label={"Role"}
                      placeholder={"Enter/Select Role"}
                      value={selectValue.Role}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Role: e })
                      }
                      options={options[1]}
                      isDisabled={selectValue.Name ? false : true}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row  gap-5 w-100">
                    <span className="Advanced-dropdown-width">
                      <Select
                        label={"Postal Pin Code"}
                        placeholder={"Enter Postal Pin Code"}
                        value={selectValue.Postal}
                        onChange={(e) =>
                          setSelectValue({ ...selectValue, Postal: e })
                        }
                        options={options[1]}
                        isDisabled={selectValue.Role ? false : true}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="w-100 d-flex flex-column gap-3">
                  <div className="col-md-4 d-flex flex-row gap-5 w-100">
                    <Select
                      label={"Name"}
                      placeholder={"Enter Name"}
                      value={selectValue.Name}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Name: e })
                      }
                      options={options[2]}
                      isDisabled={selectValue.Vertical ? false : true}
                    />

                    <Select
                      label={"Select Type of Message"}
                      placeholder={"Select Message"}
                      value={selectValue.Profession}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Profession: e })
                      }
                      options={options[1]}
                      isDisabled={selectValue.Name ? false : true}
                    />
                  </div>
                  <div className="col-md-4 d-flex gap-5 flex-row w-100">
                    <Select
                      label={"Reference"}
                      placeholder={"Enter Reference"}
                      value={selectValue.Reference}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Reference: e })
                      }
                      options={options[3]}
                      isDisabled={selectValue.Profession ? false : true}
                    />

                    <Select
                      label={"Select Type of Message"}
                      placeholder={"Select Message"}
                      value={selectValue.Postal}
                      onChange={(e) =>
                        setSelectValue({ ...selectValue, Postal: e })
                      }
                      options={options[4]}
                      isDisabled={selectValue.Reference ? false : true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                label={"Submit"}
                className={"Button_Style Button_Font w-25"}
                isDisabled={selectValue.Postal ? false : true}
                backgroundcolor={selectValue.Postal ? "#054694" : "#4c729e"}
                onClick={handleClearInput}
              />
            </div>
          </>
        }
      />
    </>
  );
}

export default AdvancedSearch;
