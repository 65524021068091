import React from "react";
import { Modal } from "react-bootstrap";
import "./ModalComponent.css";
import { AiOutlineClose } from "react-icons/ai";

const ModalComponent = ({
  show,
  Title,
  onClick,
  Content,
  isGradient,
  size,
}) => {
  return (
    <div>
      <Modal show={show} size={size} centered>
        <div className="d-flex flex-column ">
          <div>
            <Modal.Header
              className={`d-flex justify-content-between align-items-center ${
                !isGradient && "modalComponent-header"
              }`}
            >
              <Modal.Title>
                <div className={`${!isGradient && "modalComponent-title"}`}>
                  {Title}
                </div>
              </Modal.Title>
              <AiOutlineClose
                className={`Icon_Style ${
                  isGradient ? "white" : "modalComponent-title"
                }`}
                onClick={onClick}
              />
            </Modal.Header>
          </div>

          <div
            className={`p-3 ${
              isGradient ? "Gradient_Style" : "NonGradient_Style"
            }`}
          >
            <div
              className={`d-flex flex-column gap-3 ${
                isGradient ? "Modal_WhiteDiv" : "Modal_GreyDiv"
              }`}
            >
              {Content}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Modal.defaultProps = {
  show: false,
  Title: "",
  onClick: () => {},
  Content: "",
  isGradient: false,
  size: "lg",
};

export default ModalComponent;
