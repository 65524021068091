export const SignUpOTPVerify = (index, e, setOtpNumber1, otpNumber1) => {
  let tempArr = [...otpNumber1];
  tempArr[index] = e.target.value;
  setOtpNumber1(tempArr);
  if (index < otpNumber1.length - 1 && e.target.value !== "") {
    document.getElementById(`input_${index + 1}`).focus();
  } else if (e.target.value === "" && index !== 0) {
    document.getElementById(`input_${index - 1}`).focus();
  }
};
export const SignInOTPVerify = (index, e, setOtpNumber, otpNumber) => {
  let tempArr = [...otpNumber];
  tempArr[index] = e.target.value;
  setOtpNumber(tempArr);
  if (index < otpNumber.length - 1 && e.target.value !== "") {
    document.getElementById(`input_${index + 1}`).focus();
  } else if (e.target.value === "" && index !== 0) {
    document.getElementById(`input_${index - 1}`).focus();
  }
};

export const detectDevice = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (
    userAgent.search("iphone") > -1 ||
    userAgent.search("ipad") > -1 ||
    userAgent.search("ipod") > -1 ||
    userAgent.search("android") > -1
  ) {
    return "mobile";
  } else {
    return "desktop";
  }
};

export const notEmpty = (value) => {
  if(value !== '' && value !== undefined && value !== "undefined" && value !== null && value !== "null"){
    return value;
  } else {
    return '';
  }

}

export const isEmpty = (value) => {
  return (value === undefined || value === null || value.length <= 0 || value === 'null' || value === 'undefined') ? true : false;
}
