// components/App.js
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import MenuRoutes from "./MenuRoutes";
// Start Packages
import "bootstrap/dist/css/bootstrap.min.css";
// End Packages

import "./styles/main.css";
import "./styles/style.css";
import "./containers/Common.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  const CredsValues = useSelector((state) => state);

  console.log(CredsValues.user);

  useEffect(() => {
    const getProfile = async () => {
      const token = await localStorage.getItem("accessToken");
      
      // if(token === null){

      //   dispatch(logout());
      //   // Logout();
      // } else {

      // }
    }
    getProfile();
  },[]);

  return (
    <>
    <Router>
      <div>
        <MenuRoutes user={CredsValues?.user} />
      </div>
    </Router>
    <ToastContainer />
    </>
    
  );
};

export default App;
