import React from "react";
import "./ContactBar.css";
import Read from "../../../Assets/Read.png";
import UnRead from "../../../Assets/Unread.png";

const ContactBar = ({
  key,
  profilePic,
  status,
  name,
  role,
  description,
  msg,
  rightSideContent,
  className,
  seen,
}) => {
  return (
    <div>
      <div
        className={`d-flex align-items-center justify-content-between px-3 ${className}`}
        key={key}
      >
        <div className="d-flex gap-3">
          <div className="d-flex flex-column justify-content-center gap-2">
            <img
              src={profilePic}
              alt="ProfilePic"
              className={`${status} Circle-Border cursor`}
            />
            {seen && <img src={seen === "Read" ? Read : UnRead} alt="Seen" />}
          </div>

          <div className="d-flex flex-column">
            <div className="Bar-Name cursor">{name}</div>
            <div className="Bar-Role cursor">{role}</div>
            <div className="Bar-MsgContent cursor">{description}</div>
            <div className="Bar-MsgContent cursor">{msg}</div>
          </div>
        </div>

        <div className="d-flex flex-column align-items-end gap-3">
          {rightSideContent}
        </div>
      </div>
    </div>
  );
};

ContactBar.defaultProps = {
  key: "",
  profilePic: "",
  status: "",
  name: "",
  role: "",
  description: "",
  msg: "",
  rightSideContent: "",
  className: "",
  seen: "",
};

export default ContactBar;
