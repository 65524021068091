import React from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateSelect = ({
  value,
  selected,
  className,
  onChange,
  placeholder,
  dropdownMode,
  maxDate,
  minDate,
  isDisabled,
}) => {
  return (
    <Datepicker
        className={className}
        selected={value}
        onChange={onChange}
        placeholderText={placeholder}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode={dropdownMode}
        maxDate={maxDate}
        disabled={isDisabled}
        />
  );
};

DateSelect.defaultProps = {
  selected: "",
  onChange: () => {},
  className: "",
  placeholder: "",
  dropdownMode: "",
  maxDate: "",
  minDate: "",
  isDisabled: false
};

export default DateSelect;
