import React from "react";
import SearchBar from "../SearchBar/SearchBar";
import FrequentlyAccessed from "../FrequentlyAccessed/FrequentlyAccessed";
import AdvancedSearch from "../AdvancedSearch/AdvancedSearch";
import ContactList from "../ContactList/ContactList";

function Contact() {
  return (
    <div>
      <span className="d-flex flex-row align-items-center justify-content-between px-2 w-100">
        <SearchBar miniSearch={false} />

        <AdvancedSearch />
      </span>
      <FrequentlyAccessed />

      <ContactList />
    </div>
  );
}

export default Contact;
