import { Navigate } from 'react-router-dom';

const Home = () => {
  console.log(localStorage.user);
    return (
        localStorage && localStorage?.length > 0 && localStorage.user !==  undefined && localStorage.user !==  "undefined" && JSON.parse(localStorage.user) ? (
        <Navigate to="/profile" />
      ): (
        <Navigate to="/login" />
      )
      
    );
};


export default Home;