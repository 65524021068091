import React from "react";

const WriteMail = () => {
  return (
    <div>
      <div className="col-md-12 d-flex align-items-center background_color p-2 overall-height">
        Write mail
      </div>
    </div>
  );
};

export default WriteMail;
