import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import KLogo from "../../Assets/Kpost_Logo.png";
import Kmail from "../../Assets/images/signup/KMail.png";
import Katchup from "../../Assets/images/signup/Katchup.png";
import Kall from "../../Assets/images/signup/Kall.png";
import KDirectory from "../../Assets/images/signup/Kdirectory.png";
import Carousel from "../common/Carousel/Carousel";
import Input from "../common/Input/Input";
import Select from "../common/Select/Select";
import { AiFillInfoCircle, AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { BsArrowLeft } from "react-icons/bs";
import "../../Assets/icons/icomoon/style.css";
import Button from "../common/Button/Button";
import CalendarDatePicker from "../common/Calendar/DateSelect.js";
import { SignUpOTPVerify } from '../../utils/validation';
import {
  GetAllCountry,
  GetAllLanguageByCountryId,
  CheckkpostIdExist,
  GetKpostIdSuggestion,
  SendOTP,
  ValidateOTP,
  postalPinCode,
  GetAllDomainByCountryId,
  UserSignup,
  CheckIsMobileNoExist
} from "../../Services/SignUp.js";
import { logout } from "../../actions/authActions";

// Log and Sri
import { useNavigate } from "react-router-dom";
import Personals from "../../Assets/images/signup/vertical_1.png";
import Businesss from "../../Assets/images/signup/Vertical_2.png";
import Institutes from "../../Assets/images/signup/Vertical_3.png";
import { MdArrowForwardIos } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { toast } from 'react-toastify';

import moment from "moment";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - 18); // Set maximum date to 18 years before today
  // Boolean useState
  const [signupBoolean, setsignupBoolean] = useState({
    signupPage: false,
    createPage: false,
    verifyNumber: false,
    modalPhoneNumber: false,
    modalSuggestion: false,
    modalKpost: false,
    isValid: true,
    category: true,
    vertical: false,
    newpage: false
  });

  // modal useState
  const [modalShow, setmodalShow] = useState({
    show: false,
    pinCodeModal: false,
  });

  //signup page 1
  const [countryAll, setcountryAll] = useState([]);
  const [languageAll, setlanguageAll] = useState([]);
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [domain, setDomain] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaName, setAreaName] = useState('');


  // signup page 2
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [signupGender, setSignupGender] = useState("");
  const [pinCode, setPinCode] = useState("");
  const initialOtp = ["", "", "", "", "", ""];
  const [otpNumber1, setOtpNumber1] = useState(initialOtp);
  const [otpValidationStatus, setOtpValidationStatus] = useState(false);
  const [timer, setTimer] = useState(null);
  const [disableResend, setDisableResend] = useState(true);
  const [KpostIdSuggestList, setKpostIdSuggestList] = useState([]);

  // signup page 3
  const [kpostid, setKpostid] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [hasInteracted, setHasInteracted] = useState(false);
  const [confirmpassword, setConfirmpassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [agreeChecked, setAgreeChecked] = useState(false);

  // Modal For Mobile
  const handleClose = () => {
    setmodalShow({ ...modalShow, show: false })
    setOtpNumber1(initialOtp);
    setMobileNumber("");
  };

  const handleCloseOTP = () => {
    setmodalShow({ ...modalShow, show: false })
    setOtpNumber1(initialOtp);
  };

  const validatePassword = (inputPassword) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(inputPassword);
  };

  const Email = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(emailValue);
    setsignupBoolean({ ...signupBoolean, isValid: isValidEmail })
  };

  const Mobile = (e) => {
    const input = e.target.value;
    const NumberRegex = /^\d{0,10}$/;
    if (NumberRegex.test(input)) {
      setMobileNumber(input);
    }
  };

  const SetAndValidateKpostId = (e) => {
    const newValue = e.target.value;
    setKpostid(newValue);
    if (/^[a-zA-Z0-9]*$/.test(newValue)) {
      if (newValue.length >= 5 || newValue === '') {
        setMessage('');
        if (newValue.length >= 5) {
          CheckIsKpostIdAvailable(newValue);
        }
      } else {
        setMessage('KPOST ID must be at least 5 characters.');
      }
    } else {
      setMessage('Only alphanumeric characters are allowed.');
    }
  }

  const PincodeModal = (e) => {
    const input = e.target.value.trim();
    if (input.length <= 6 && /^\d{0,6}$/.test(input)) {
      setPinCode(input);
      if (input.length === 6) {
        getPostalCodeDetails(input);
      }
    }
  }

  const data = [
    {
      Img: Personals,
      Name: "Personal",
    },
    {
      Img: Businesss,
      Name: "Business",
    },
    {
      Img: Institutes,
      Name: "Institute",
    },
  ];

  const Categories = [
    {
      title: "Small",
      content:
        "All Small Business organizations, all Small Institutions like Associations, Clubs, Societies or Personal with a number of users up to 200.",
    },
    {
      title: "Medium",
      content:
        "All Medium Business organizations or all Medium Institutions like Associations, Clubs, Societies, Political parties etc. with a number of users up to 400.",
    },
    {
      title: "Large",
      content:
        "All Large Business organizations or all Large Institutions like Associations, Clubs, Societies, Political parties etc. with a number of users of more than 400 and all Government entities like Panchayats, municipalities, Corporations, and Ministries at all levels.",
    },
    {
      title: "Multi-National",
      content:
        "All Business organizations or all Institutions like Associations, Clubs, Societies, etc., and all International organizations which are operating in multiple countries.",
    },
  ];

  const CarouselData = [
    {
      Img: Kmail,
      class: "icon-KP_03-KMail",
      Title: "Kmail",
      Text: "All new Mailing experience with fresh approach. No more drab inbox and outboxes. Convenient contact-wise folders for you to have comprehensive view of all mails of a contact.",
    },
    {
      Img: Katchup,
      class: "icon-KP_04-Katchup",
      Title: "Katchup",
      Text: "An instant digital messaging module with numerous patented features and seamless integration with Mailing and Calling.",
    },
    {
      Img: Kall,
      class: "icon-KP_05-Kall",
      Title: "Kall",
      Text: "Kall offers a set of new experiences with innovative IP voice, IP video, Mobile, Landline, Duo and Facetime calling - All from KPOST.",
    },
    {
      Img: KDirectory,
      class: "icon-KP_06-KDirectory",
      Title: "KDirectory",
      Text: "Global KPOST Directory that connects you to any other KPOST user from individual users, Business users, Institutional Users and Government Users across the globe.",
    },
  ];



  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const CountryGetAll = async () => {
    let response = await GetAllCountry();
    if (response.status === "SUCCESS") {
      if (response.data.length > 0) {
        let dataOPtion = response.data.map((e) => {
          return {
            ...e,
            label: `+${e.countryCode}     ${e.countryName}`,
            value: `${e.countryID}`,
          };
        });
        setcountryAll(dataOPtion);
      }
    }
  };

  const LanguageGetAll = async (countryId) => {
    setlanguageAll([]);
    let bod = {
      countryID: countryId
    }
    let response = await GetAllLanguageByCountryId(bod);
    if (response?.status === "SUCCESS") {
      if (response.data.length > 0) {
        let dataOPtion = response.data.map((e) => {
          return {
            ...e,
            label: `${e.language}`,
            value: `${e.id}`,
          };
        });
        setlanguageAll(dataOPtion);
      }
    } else {
      setlanguageAll([]);
    }
  };

  const GetDomainByCountryId = async () => {
    let bod = {
      countryID: country.countryID
    }
    let response = await GetAllDomainByCountryId(bod);
    if (response?.status === "SUCCESS") {
      if (response.data.length > 0) {
        let formattedDomains = response.data.map((e) => ({
          label: e,
          value: e
        }));
        setDomain(formattedDomains);
      }
    } else {
      setDomain([]);
    }
  };

  const sendOTP = async (mobileNumber) => {
    const enteredNumber = mobileNumber;

    if (enteredNumber.length !== 10) {
      toast.error('Invalid Mobile Number', {
        position: toast.POSITION.TOP_RIGHT
      }); return;
    }

    try {
      const existsObj = {
        countryID: country.countryID,
        mobileNumber: mobileNumber,
      };

      const response = await CheckIsMobileNoExist(existsObj);
      if (response?.statusCode === 500 && response.data === true) {
        toast.error('Mobile number already exists!', {
          position: toast.POSITION.TOP_RIGHT
        });
      } else if (response?.statusCode === 200) {
        const bod = {
          countryID: country.countryID,
          mobileNumber: mobileNumber,
          requestType: "signup",
        };

        const otpResponse = await SendOTP(bod);
        if (otpResponse?.status === "SUCCESS") {
          setmodalShow({ ...modalShow, show: true });
          setsignupBoolean({ ...signupBoolean, modalPhoneNumber: true })
          toast.success(otpResponse?.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setTimer(30);
          setDisableResend(true);
          const interval = setInterval(() => {
            setTimer(prev => (prev > 0 ? prev - 1 : null));
          }, 1000);

          setTimeout(() => {
            clearInterval(interval);
            setDisableResend(false);
          }, 30000);
        } else {
          toast.error(otpResponse?.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      } else {
        toast.error(response?.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validateOTP = async (valotp, mobileNumber) => {
    try {
      const otp = Array.isArray(valotp) ? valotp.join('') : valotp;
      const bod = {
        otp: otp,
        countryID: country.countryID,
        mobileNumber: mobileNumber,
        sendDate: Date.now()
      }

      const response = await ValidateOTP(bod);

      if (response?.status === "SUCCESS") {
        setOtpValidationStatus(true);
        toast.success(response?.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        handleCloseOTP();
        setsignupBoolean({ ...signupBoolean, verifyNumber: true })
      } else {
        setOtpValidationStatus(false);
        toast.error('Invalid OTP. Please enter the correct code', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
    }
  };



  const getPostalCodeDetails = async (pinCode) => {
    setState('');
    setCity('');
    setAreaName('')
    setAreaOptions([])
    try {
      let bod = {
        postalCode: pinCode
      }

      let response = await postalPinCode(bod);

      if (response?.status === "SUCCESS" && response.data.length > 0) {
        setmodalShow({ ...modalShow, pinCodeModal: true, show: true });
        setsignupBoolean({ ...signupBoolean, modalPhoneNumber: false, modalSuggestion: false, modalKpost: false });
        const data = response.data[0];
        setState(data.stateName);
        setCity(data.cityName);

        let arreaData = data.areaName.map(e => ({
          ...e,
          label: `${e}`,
          value: `${e}`,
        }));
        setAreaOptions(arreaData);
        setOtpValidationStatus(true);
        console.log(data.areaName, "response");
        console.log(areaOptions, "response");
        if(data.areaName.length === 1){
          setAreaName(arreaData[0]);
        }
      } else {
        setOtpValidationStatus(false);
        toast.warning('Invalid postal pincode', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error("Error getting postal code details:", error);
    }
  };

  const CheckIsKpostIdAvailable = async (kpostidVal) => {
    try {
      let bod = {
        kpostID: kpostidVal + '' + selectedDomain.value,
        firstName,
        lastName,
        mobileNumber,
      };
      let response = await CheckkpostIdExist(bod);

      if (response?.status === "SUCCESS") {
        setMessage('KpostID is Available. can be used!');
      } else {
        setMessage('This KpostID is already exists!');
      }
    } catch (error) {
      console.error("Error getting Kpost ID suggestions:", error);
    }
  };


  const GetKpostIDsuggestionList = async () => {
    try {
      let bod = {
        firstName,
        lastName,
        mobileNumber,
      };
      let response = await GetKpostIdSuggestion(bod);

      if (response?.status === "SUCCESS") {
        const suggestedList = response.data.map(item => ({ ListName: item }));
        setKpostIdSuggestList(suggestedList);
      }
    } catch (error) {
      console.error("Error getting Kpost ID suggestions:", error);
    }
  };


  const SignUp = async () => {
    // debugger
    let DOB = moment(dateOfBirth).format("YYYY-MM-DD");
    try {
      let SignUpObj = {
        kpostID: kpostid + '' + selectedDomain.value,
        firstName,
        lastName,
        mobileNumber,
        createdDate: Date.now(),
        password: confirmpassword,
        gender: signupGender.value,
        dateOfBirth: DOB,
        countryCode: country.countryCode,
        userProfile: {
          landLineNumber: "",
          referalId: "",
          pinCode: pinCode,
          areaName: areaName.value
        }
      };
      let response = await UserSignup(SignUpObj);
      if (response?.status === "SUCCESS") {
        setsignupBoolean({ ...signupBoolean, modalPhoneNumber: false, modalSuggestion: false, modalKpost: true });
        setmodalShow({ ...modalShow, show: true });
      }
    } catch (error) {
      console.error("Error during sign up:", error);
    }
  };


  useEffect(() => {
    dispatch(logout());
    CountryGetAll();
  }, []);

  return (
    <>
      <div className="col-md-12 Container Back-Image d-flex justify-content-around align-items-center">
        <div className="col-md-5">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex align-items-center gap-3">
              <div className="Img_Border d-flex align-items-center">
                <img src={KLogo} width="100%" alt="" />
              </div>
              <div className="Kpost-Font">Welcome to KPOST</div>
            </div>

            <div>
              <Carousel Carousel={CarouselData} />
            </div>
          </div>
        </div>

        {/* <div className="col-md-2"></div> */}

        <div className="col-md-5 px-5 ">
          {signupBoolean.category &&
            <div className="Signup_Main nuntio-font h-90-vh">
              <div className="d-flex flex-column">
                <div className="category-label">KPOST</div>
                <div className="category-sub-label">
                  Category definitions proposed
                </div>
              </div>

              <div className="d-flex flex-column gap-3 pt-3 Height-Home-Page">
                {Categories.map((item, index) => (
                  <div
                    className="d-flex flex-column gap-1 category-contentBox-layout px-3 py-2"
                    key={index}
                    style={{ gap: "5px" }}
                  >
                    <div className="category-title">{item.title}</div>
                    <div className="category-content">{item.content}</div>
                    <div className="d-flex align-items-center">
                      <span
                        className="category-link"
                        onClick={() => {
                          setsignupBoolean({ ...signupBoolean, category: false, vertical: true })
                        }}
                      >
                        Continue
                        <MdOutlineKeyboardArrowRight size={15} />
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex justify-content-center Login_A_Font mt-2 mb-3">
                Already Have an Account? &nbsp;{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="L_Font"
                >
                  {" "}
                  LogIn
                </span>
              </div>

            </div>
          }
          {signupBoolean.vertical &&
            <>
              {/* sritharan */}
              <div className="Vertical_Main d-flex flex-column gap-4 h-90-vh">
                <div className="d-flex flex-column ">
                  <div className="Vertical_fonts d-flex justify-content-between align-items-center">
                    KPOST Vertical
                    <BsArrowLeft
                      className={"cursor-pointer"}
                      onClick={() => {
                        setsignupBoolean({ ...signupBoolean, signupPage: false, createPage: false, newpage: false, vertical: false, category: true })
                      }}
                      size="25"
                    />
                  </div>
                  <div className="Select_account">Select Account Option</div>
                </div>
                <div className="d-flex flex-column gap-3 pt-3 Height-Home-Page">
                {data.map((x, i) => (
                  <div
                    className="d-flex flex-row align-items-center border_inside"
                    key={i}
                  >
                    <img src={x.Img} alt={x.Name} width="80" height="80" />
                    <div className="d-flex flex-row align-items-center justify-content-between Inside_alignment">
                      <span className="personal_size ">{x.Name}</span>
                      <span>
                        <MdArrowForwardIos
                          size={20}
                          className={i === 0 ? "Cursor_pointer" : ""}
                          onClick={() => {
                            if (i === 0) {
                              setsignupBoolean({ ...signupBoolean, vertical: false, signupPage: true })
                            }
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ))}
                </div>
                <div className="d-flex justify-content-center Login_A_Font mt-2 mb-3">
                Already Have an Account? &nbsp;{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="L_Font"
                >
                  {" "}
                  LogIn
                </span>
              </div>
              </div>
              {/* sritharan */}
            </>
          }
          {signupBoolean.signupPage &&
            <div className="Signup_Main h-90-vh">
              <div className="d-flex flex-column gap-1">
                <div className="Signup_Font d-flex justify-content-between align-items-center">
                  Sign Up
                  <BsArrowLeft
                    className={"cursor-pointer"}
                    onClick={() => {
                      setsignupBoolean({ ...signupBoolean, signupPage: false, createPage: false, newpage: false, vertical: true, category: false })
                    }}
                    size="25"
                  />
                </div>
                <div className="KP_Font">KPOST Personal</div>
              </div>

              <div className="d-flex flex-column justify-content-between h-85">
                <div className="d-flex flex-column gap-4 mt-3">
                  <div className="d-flex flex-column gap-1">
                    <Select
                      label={"Country"}
                      placeholder={"Choose Country"}
                      isDropdown
                      options={countryAll}
                      value={country}
                      onChange={(e) => {
                        setCountry(e);
                        LanguageGetAll(e.value)
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      label={"Language"}
                      placeholder={"Choose Language"}
                      isDropdown
                      options={languageAll}
                      value={language}
                      onChange={(e) => {
                        setLanguage(e);
                        GetDomainByCountryId();
                      }}
                      isDisabled={country ? false : true}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      label={"KPOST Domain"}
                      placeholder={"Choose KPOST Domain"}
                      isDropdown
                      options={domain}
                      value={selectedDomain}
                      onChange={(e) => {
                        setSelectedDomain(e);
                        setKpostid("")
                        setMessage("")
                      }}
                      isDisabled={language ? false : true}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <Button
                    onClick={() => {
                      setsignupBoolean({ ...signupBoolean, signupPage: false, createPage: true })
                    }}
                    className="Button_Font Button_Style"
                    disabled={selectedDomain ? false : true}
                    backgroundcolor={selectedDomain ? "#054694" : "#4c729e"}
                    color={"#fffff"}
                    label={"Continue"}
                  />

                  <div className="d-flex justify-content-center Login_A_Font mt-2">
                    Already Have an Account? &nbsp;{" "}
                    <span onClick={() => navigate("/login")} className="L_Font">
                      {" "}
                      LogIn
                    </span>
                  </div>
                </div>
              </div>
            </div>
          }
          {signupBoolean.createPage &&
            <div className="Signup_Main h-90-vh">
              <div className="d-flex flex-column gap-1">
                <div className="Signup_Font d-flex justify-content-between align-items-center">
                  Sign Up
                  <BsArrowLeft
                    className={"cursor-pointer"}
                    onClick={() => {
                      setsignupBoolean({ ...signupBoolean, signupPage: true, createPage: false, newpage: false, vertical: false, category: false })
                    }}
                    size="25"
                  />
                </div>
                <div className="KP_Font">KPOST Personal</div>
              </div>

              <div className="d-flex flex-column justify-content-between Signup_height">
                <div className="d-flex flex-column gap-3 mt-3">
                  <div className="d-flex flex-column gap-1">
                    <Input
                      label={"Mobile Number"}
                      placeholder="Enter the Number"
                      value={mobileNumber}
                      onChange={Mobile}
                      isDisabled={signupBoolean.verifyNumber}
                      // errorMessage={!signupBoolean.IsValidMobile ? "" : "Invalid Mobile Number"}
                      showRightIcon={
                        <>
                          {signupBoolean.verifyNumber ? (
                            <>
                              <div className="d-flex align-items-center gap-1">
                                <div>
                                  <RiVerifiedBadgeFill
                                    size={23}
                                    className="IconColor_Green"
                                  />
                                </div>
                                <div className="Verify_Style_Green">
                                  Verified
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className="d-flex align-items-center gap-1 cursor-pointer"
                              onClick={() => {
                                if (mobileNumber !== "") {
                                  sendOTP(mobileNumber);
                                }
                              }}
                            >
                              <div>
                                <AiFillInfoCircle
                                  size={23}
                                  className="IconColor"
                                />
                              </div>
                              <div className="Verify_Style">Verify</div>
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Input
                      label={"First Name"}
                      placeholder="Enter the first name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value)
                      }}
                      // isDisabled={mobileNumber ? false : true}
                      isDisabled={signupBoolean.verifyNumber ? false : true}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Input
                      label={"Last Name"}
                      placeholder="Enter the last name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value)
                      }}
                      isDisabled={firstName ? false : true}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Input
                      label={"Email ID"}
                      placeholder="Enter the email id"
                      value={email}
                      onChange={Email}
                      isDisabled={lastName ? false : true}
                      errorMessage={!signupBoolean.isValid ? "Invalid Email Address" : ""}
                    // showRightIcon={
                    //   <>
                    //     {verifyEmail ? (
                    //       <>
                    //         <div className="d-flex align-items-center gap-1">
                    //           <div>
                    //             <RiVerifiedBadgeFill
                    //               size={23}
                    //               className="IconColor_Green"
                    //             />
                    //           </div>
                    //           <div className="Verify_Style_Green">
                    //             Verified
                    //           </div>
                    //         </div>
                    //       </>
                    //     ) : (
                    //       <div
                    //         className="d-flex align-items-center gap-1 cursor-pointer"
                    //         onClick={() => {
                    //           handleShow();
                    //           setModalPhoneNumber(false);
                    //           setModalEmail(true);
                    //         }}
                    //       >
                    //         <div>
                    //           <AiFillInfoCircle
                    //             size={23}
                    //             className="IconColor"
                    //           />
                    //         </div>
                    //         <div className="Verify_Style">Verify</div>
                    //       </div>
                    //     )}
                    //   </>
                    // }
                    />
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <Select
                      label={"Gender"}
                      placeholder="Select Gender"
                      isDropdown
                      options={gender}
                      value={signupGender}
                      onChange={(e) => {
                        setSignupGender(e)
                      }}
                      isDisabled={email ? false : true}
                    />
                  </div>

                  <div className="d-flex flex-column gap-1">
                  <label className={`Label_Font`}>
                    Date of Birth
                  </label>
                    <CalendarDatePicker
                      value={dateOfBirth}
                      onChange={(date) => {
                        setDateOfBirth(date);
                      }}
                      placeholder="Select Date of Birth"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={maxDate}
                      className={"w-100 Input_Style Input_Back_Color"}
                      isDisabled={signupGender ? false : true}
                    />
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <Input
                      label={"Postal Pincode"}
                      placeholder="Enter postal pincode"
                      value={pinCode}
                      onChange={(e) => {
                        PincodeModal(e);
                      }}
                      isDisabled={dateOfBirth ? false : true}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-3">
                <Button
                  onClick={() => {
                    setsignupBoolean({ ...signupBoolean, signupPage: false, createPage: false, newpage: true })
                  }}
                  className="Button_Font Button_Style w-100"
                  disabled={pinCode ? false : true}
                  backgroundcolor={pinCode ? "#054694" : "#4c729e"}
                  color={"#fffff"}
                  label={"Continue"}
                />
              </div>

              <div className="d-flex justify-content-center Login_A_Font mt-2">
                Already Have an Account? &nbsp;{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="L_Font"
                >
                  {" "}
                  LogIn
                </span>
              </div>
            </div>
          }
          {signupBoolean.newpage &&
            <div className="Signup_Main h-90-vh">
              <div className="d-flex flex-column gap-1">
                <div className="Signup_Font d-flex justify-content-between align-items-center">
                  Sign Up
                  <BsArrowLeft
                    className={"cursor-pointer"}
                    onClick={() => {
                      setsignupBoolean({ ...signupBoolean, signupPage: false, createPage: true, newpage: false, vertical: false, category: false })
                    }}
                    size="25"
                  />
                </div>
                <div className="KP_Font">KPOST Personal</div>
              </div>

              <div className="d-flex flex-column justify-content-between h-85">
                <div className="d-flex flex-column gap-3 mt-3">
                  <div className="d-flex flex-column gap-1">
                    {/* <Input
                      label={"Preferred KPOST ID"}
                      placeholder={"Enter Preferred KPOST ID Upto 8 Characters"}
                      value={kpostid}
                      onChange={(e) => {
                        setKpostid(e.target.value);
                      }}
                    /> */}
                    <div style={{ position: 'relative' }}>
                      <input
                        label={"Preferred KPOST ID"}
                        placeholder="Enter Preferred KPOST ID"
                        value={kpostid}
                        onChange={(e) => {
                          SetAndValidateKpostId(e)
                        }}
                        className="Input_Style"
                        style={{ width: '50%' }}
                      />
                      <div
                        className="Input_Style Input_Back_Color"
                        style={{
                          position: 'absolute',
                          // top: '0',
                          right: '0',
                          bottom: '0',
                          width: '49%',
                          border: 'none',
                          pointerEvents: 'none',
                          padding: '7px',
                        }}
                      >
                        {selectedDomain.value}
                      </div>
                    </div>


                    {message && (
                      <div style={{ color: message.includes('Available') ? 'green' : 'red' }}>
                        {message}
                      </div>
                    )}
                    <div className="d-flex justify-content-end change_color ">
                      <span
                        onClick={() => {
                          setsignupBoolean({ ...signupBoolean, modalPhoneNumber: false, modalSuggestion: true, modalKpost: false })
                          setmodalShow({ ...modalShow, show: true });
                          GetKpostIDsuggestionList();
                        }}
                        className="Cursor_pointer"
                      >
                        Get Suggestion from KPOST
                      </span>
                    </div>
                  </div>

                  {/* <div className="d-flex flex-column gap-1">
                    <Input
                      className="Input_Style Input_Back_Color"
                      label={"Password"}
                      placeholder={"Enter Password"}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      isPassword={true}
                      isDisabled={kpostid ? false : true}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Input
                      className="Input_Style Input_Back_Color"
                      label={"Confirm Password"}
                      placeholder={"Enter Confirm Password "}
                      value={confirmpassword}
                      onChange={(e) => {
                        setConfirmpassword(e.target.value);
                      }}
                      isPassword={true}
                      isDisabled={password ? false : true}
                    />
                  </div> */}
                  <div className="d-flex flex-column gap-1">
                    <Input
                      className="Input_Style Input_Back_Color"
                      label={"Password"}
                      placeholder={"Enter Password"}
                      value={password}
                      onChange={(e) => {
                        const newPassword = e.target.value;
                        setPassword(newPassword);
                        if (newPassword.length >= 6) {
                          if (!validatePassword(newPassword)) {
                            setPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character.');
                          } else if (newPassword !== confirmpassword && confirmpassword !== "") {
                            setPasswordError('Password and Confirm Password do not match');
                          } else {
                            setPasswordError('');
                          }
                        } else {
                          setPasswordError('Password must be at least 6 characters long.');
                        }
                      }}
                      onFocus={() => setHasInteracted(true)}
                      isPassword={true}
                      isDisabled={kpostid ? false : true}
                    />
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <Input
                      className="Input_Style Input_Back_Color"
                      label={"Confirm Password"}
                      placeholder={"Enter Confirm Password "}
                      value={confirmpassword}
                      onChange={(e) => {
                        setConfirmpassword(e.target.value);
                        if (e.target.value !== password && e.target.value.length >= 6) {
                          setPasswordError('Password and Confirm Password do not match');
                        }
                        else {
                          setPasswordError('');
                        }
                      }}
                      onFocus={() => setHasInteracted(true)}
                      isPassword={true}
                      isDisabled={password ? false : true}
                    />
                  </div>

                  {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
                  <div className="d-flex flex-row align-items-baseline">
                    <input type={"checkbox"}
                      checked={agreeChecked}
                      onChange={() => setAgreeChecked(!agreeChecked)}
                    />
                    &nbsp;
                    <span className="Checkbox_color"> I Agree With
                      <span className="change_color Cursor_pointer">
                        Data Policy, Privacy Policy, Terms and Conditions
                      </span>
                    </span>

                  </div>
                </div>

                <div className="d-flex flex-column">
                  <Button
                    onClick={() => {
                      if (agreeChecked) {
                        SignUp();
                      }
                    }}
                    className="Button_Font Button_Style w-100"
                    disabled={!agreeChecked}
                    backgroundcolor={agreeChecked ? "#054694" : "#4c729e"}
                    color={"#fffff"}
                    label={"Continue"}
                  />
                </div>
              </div>
            </div>
          }

          {/* Modal For Mobile */}

          <Modal show={modalShow.show} dialogClassName="modal-50w" centered>
            {signupBoolean.modalPhoneNumber &&
              <div className="d-flex flex-column">
                <div>
                  <Modal.Header className="d-flex justify-content-between align-items-center">
                    <Modal.Title>Enter Your OTP</Modal.Title>
                    <AiOutlineClose
                      className="Icon_Style"
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </Modal.Header>
                </div>
                
                <div className="p-3 Modal_Style">
                  <div className="Modal_Div d-flex flex-column gap-2">
                    <div className="Otp_Font">
                      6 Digit Code has been sent to &nbsp;
                      <span className="NumberFont">+91 {mobileNumber}</span>
                    </div>

                    <div className="d-flex gap-4">
                      {otpNumber1.map((item, index) => (
                        <div key={index}>
                          <Input
                            id={`input_${index}`}
                            className={"text-center"}
                            value={otpNumber1[index]}
                            onChange={(e) => {
                              SignUpOTPVerify(index, e, setOtpNumber1, otpNumber1)
                              const newOtpNumber1 = [...otpNumber1];
                              newOtpNumber1[index] = e.target.value;
                              setOtpNumber1(newOtpNumber1);
                              if (newOtpNumber1.filter(Boolean).length === 6) {
                                validateOTP(newOtpNumber1.join(''), mobileNumber);
                              }
                            }}
                            maxLength={"1"}
                            placeholder={"0"}
                          />
                        </div>
                      ))}
                    </div>
                    <div>
                      {timer !== null && (
                        <div className="d-flex justify-content-end Otp_Font">
                          <span
                            className={`cursor-pointer Color ${disableResend ? 'disabled' : ''}`}
                            onClick={disableResend ? null : () => sendOTP(mobileNumber)}
                            style={{
                              pointerEvents: disableResend ? 'none' : 'auto',
                              color: disableResend ? 'rgb(100 139 185)' : '#054694',
                            }}
                          >
                            Resend OTP {timer > 0 && `(${timer}s)`}
                          </span>
                        </div>
                      )}
                    </div>

                    {/* <div>
                      <Button
                        onClick={() => {
                          handleClose();
                          setsignupBoolean({ ...signupBoolean, verifyNumber: true })
                        }}
                        className="Button_Font Button_Style mt-2 w-100"
                        disabled={!otpValidationStatus}
                        backgroundcolor={otpValidationStatus ? "#054694" : "#4c729e"}
                        color="#ffffff" */}
                    {/* // label="Continue"
                      // disabled={ */}
                    {/* //   otpNumber1.filter((x) => x !== "").length === 6
                      //     ? false
                      //     : true
                      // }
                      // backgroundcolor={ */}
                    {/* //   otpNumber1.filter((x) => x !== "").length === 6
                      //     ? "#054694"
                      //     : "#4c729e"
                      // }
                      // color={"#fffff"}
                      // label={"Continue"}
                      // /> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            }
            {modalShow.pinCodeModal &&
              <div className="d-flex flex-column">
                <div>
                  <Modal.Header className="d-flex justify-content-between align-items-center">
                    <Modal.Title>Pincode Details</Modal.Title>
                    <AiOutlineClose
                      className="Icon_Style"
                      onClick={() => {
                        setPinCode('')
                        setmodalShow({ ...modalShow, show: false, pinCodeModal: false });
                      }}
                    />
                  </Modal.Header>
                </div>
                <div className="p-3 Modal_Style">
                  <div className="Modal_Div d-flex flex-column gap-3">
                    <div className="d-flex flex-column ">
                      <Input
                        label={"Pincode"}
                        value={pinCode}
                        onChange={PincodeModal}
                        className={"pinCode-Modal-input"}
                        isDisabled={pinCode !== '' ? true : false}
                      />
                    </div>

                    <div className="d-flex flex-column ">
                      <Input
                        label={"State"}
                        value={state}
                        className={"pinCode-Modal-input"}
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <Input label={"City"} value={city}
                        className={"pinCode-Modal-input"} />
                    </div>
                    <div className="d-flex flex-column">
                      <Select
                        label="Area"
                        placeholder="Select Area"
                        options={areaOptions}
                        value={areaName}
                        onChange={(e) => {
                          setAreaName(e)
                        }}
                      />
                    </div>

                    <div className="d-flex justify-content-center mt-2 gap-5">
                      <button
                        className="Modal_Cancel"
                        onClick={() => {
                          setPinCode('')
                          setmodalShow({ ...modalShow, show: false, pinCodeModal: false })
                        }}
                      >
                        Cancel
                      </button>
                      {/* <button
                        className="pinCode-Modal-button Modal_Pincode"
                        onClick={() => {
                          setmodalShow({ ...modalShow, show: false, pinCodeModal: false })
                        }}
                      >
                        Confirm
                      </button> */}
                      <Button
                        onClick={() => {
                          setmodalShow({ ...modalShow, show: false, pinCodeModal: false })
                        }}
                        className="Button_Font Button_Style pinCode-Modal-button Modal_Pincode"
                        disabled={areaName ? false : true}
                        backgroundcolor={areaName ? "#054694" : "#4c729e"}
                        color={"#fffff"}
                        label={"Confirm"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            {signupBoolean.modalSuggestion &&
              <div className="d-flex flex-column">
                <div>
                  <Modal.Header className="d-flex justify-content-between align-items-center">
                    <Modal.Title>Suggestion from KPOST</Modal.Title>
                    <AiOutlineClose
                      className="Icon_Style"
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </Modal.Header>
                </div>
                <div className="p-3 Modal_Style">
                  <div className="Modal_Div d-flex flex-column gap-2">
                    <div className="d-flex flex-column gap-2">
                      <div>
                        <label className="Label_Font">Suggestion Lists</label>
                      </div>
                      <div>
                        {KpostIdSuggestList.map((x) => {
                          return (
                            <div
                              key={x.ListName}
                              className="Borber-Bottom font-weight-bolder Cursor_pointer"
                              onClick={() => {
                                setKpostid(x.ListName);
                                setsignupBoolean({ ...signupBoolean, modalPhoneNumber: false, modalSuggestion: false, modalKpost: false });
                                setmodalShow({ ...modalShow, show: false })
                              }}
                            >
                              {x.ListName}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="d-flex gap-4 mt-2">
                      <div className="w-100">
                        <Input
                          label={"Enter New KPOST ID"}
                          className="w-100 Label_Font"
                          placeholder="Enter KPOST ID"
                          value={kpostid}
                          onChange={(e) => {
                            setKpostid(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        handleClose();
                        // setVerifyEmail(true);
                      }}
                      className="Button_Style mt-2"
                    >
                      <button className="Button_Font">Next</button>
                    </div>
                  </div>
                </div>
              </div>
            }
            {signupBoolean.modalKpost &&
              <div className="d-flex flex-column">
                <div>
                  <Modal.Header className="d-flex justify-content-between align-items-center">
                    <Modal.Title>KPost</Modal.Title>
                    <AiOutlineClose
                      className="Icon_Style"
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </Modal.Header>
                </div>
                <div className="p-3 Modal_Style">
                  <div className="Modal_Div d-flex flex-column gap-2">
                    <div className="d-flex flex-column B_Bot Con_Font">
                      Congratulations! You have Successfully completed the
                      Signup.
                    </div>

                    <div className="d-flex flex-column gap-2 Label_Font">
                      <div className="f-14">
                        <b>Note:</b>
                        <span>
                          {" "}
                          You can Login Using any of the Following for KPOST ID{" "}
                        </span>
                      </div>
                      <div className="">
                        <ul className="d-flex flex-column gap-2 px-4">
                          <li>
                            <div className="Li_Div_Font">+91 {mobileNumber}</div>
                            <div className="Li_Font">
                              (Registered Mobile No)
                            </div>
                          </li>
                          <li>
                            <div className="Li_Div_Font">{kpostid}{selectedDomain.value}</div>
                            <div className="Li_Font">(Registered KPOST ID)</div>
                          </li>
                          <li>
                            <div className="Li_Div_Font">
                              {kpostid}{selectedDomain.value}
                            </div>
                            <div className="Li_Font">
                              (Registered KPOST ID@KPOST Domain)
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        handleClose();
                        // setVerifyEmail(true);
                        navigate("/login");
                      }}
                      className="Button_Style mt-2"
                    >
                      <button className="Button_Font">Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </Modal>
          {/* Modal */}

          {/* Modal for Email */}
          {/* { modalEmail ? (
              <div className="d-flex flex-column">
                <div>
                  <Modal.Header className="d-flex justify-content-between align-items-center">
                    <Modal.Title>Enter Your OTP</Modal.Title>
                    <AiOutlineClose
                      className="Icon_Style"
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </Modal.Header>
                </div>
                <div className="p-3 Modal_Style">
                  <div className="Modal_Div d-flex flex-column gap-2">
                    <div className="Otp_Font d-flex flex-column">
                      6 Digit Code has been sent to the given Email ID &nbsp;
                      <span className="NumberFont">{email}</span>
                    </div>

                    <div className="d-flex gap-4">
                      <div>
                        <Input
                          className="text-center"
                          placeholder="0"
                          value={otpNumber1}
                          onChange={Otp1}
                        />
                      </div>
                      <div>
                        <Input />
                      </div>
                      <div>
                        <Input />
                      </div>
                      <div>
                        <Input />
                      </div>
                      <div>
                        <Input />
                      </div>
                      <div>
                        <Input />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end Otp_Font">
                      <span className="cursor-pointer Color">Resend OTP</span>
                    </div>
                    <div
                      onClick={() => {
                        handleClose();
                        setVerifyEmail(true);
                      }}
                      className="Button_Style mt-2"
                    >
                      <button className="Button_Font">Continue</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          } */}
          {/* Modal for Email */}
        </div>
      </div>
    </>
  );
};

export default Register;
